/* =============================================================================
   GRID
   ========================================================================== */

.container {
  margin: 0 15px;
  position: relative;
  z-index: 12;

  + .container {
    margin-top: 90px;
  }

  + .container--small-margin {
    margin-top: 45px;
  }

  &:not(.container--no-clearfix) {
    .clearfix;
  }

  &.mobile-no-m {
    @media @mobile {
      margin: 0;
    }
  }

  @media @tablet {
    margin: 0 20px;
  }

  @media @desktop {
    width: 980px;
    margin: 0 auto;
    &.small {
      width: 960px;
    }
  }

  @media @netbook {
    width: 1024px;

    &.small {
      width: 1004px;
    }
  }

  @media @wide {
    width: 1240px;
    &.small {
      width: 960px;
    }
  }

  @media @xwide {
    width: 1400px;
  }

  &.container--full {
    margin: 0;
    width: 100%;

    @media @desktop {
      margin: 0 auto;
      width: 980px;
    }

    @media @netbook {
      width: 1024px;

      &.small {
        width: 1004px;
      }
    }

    @media @wide {
      width: 1240px;
    }

    @media @xwide {
      width: 1400px;
    }
  }

  &.container--large {
    @media @xxwide {
      width: 1600px;
    }
  }

  &.container--overflow-h {
    overflow: hidden;
  }

  &.container--accent {
    background-color: @accent;
    margin: 0;
    width: 100%;

    @media @desktop {
      width: 980px;
      margin: 0 auto;
    }

    @media @netbook {
      width: 1024px;
    }

    @media @wide {
      width: 1240px;
    }

    @media @xwide {
      width: 1400px;
    }
  }

  &.container--small {
    margin: 0;
    width: 100%;

    @media @desktop {
      width: 680px;
      margin: 0 auto;
    }

    @media @netbook {
      width: 740px;
    }

    @media @wide {
      width: 800px;
    }

    @media @xwide {
      width: 840px;
    }
  }
}

.row {
  .clearfix;
  margin: 0 0 0px -30px;

  @media @tablet {
    &.g0 {
      margin-left: 0px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 0px;
      }
    }

    &.g0-late {
      margin-left: -30px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 30px;
      }
    }

    &.g20 {
      margin-left: -20px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-fifth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 20px;
      }
    }

    &.g30 {
      margin-left: -30px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-fifth,
      > .two-fifth,
      > .three-fifth,
      > .one-sixth,
      > .one-ninth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 30px;
      }
    }

    &.g80 {
      margin-left: -40px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 40px;
      }
    }
  }

  @media @desktop {
    &.g80 {
      margin-left: -80px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 80px;
      }
    }
  }

  @media @wide {
    &.g0-late {
      margin-left: -0px;

      > .one-half,
      > .two-third,
      > .one-third,
      > .three-fourth,
      > .one-fourth,
      > .one-sixth,
      > .three-tenth,
      > .seven-tenth,
      > .one-full {
        padding-left: 0px;
      }
    }
  }

  &.hold {
    .one-fourth {
      width: 25%;
    }

    .three-fourth {
      width: 75%;
    }
  }

  &.hold.hold {
    .one-half {
      width: 50%;
    }

    .one-third {
      width: 33.33%;
    }

    .one-fifth {
      width: 20%;
    }

    .two-fifth {
      width: 40%;
    }

    .one-ninth {
      width: 11.11%;
    }
  }

  &.late {
    .one-third {
      width: 100%;
      @media @tablet {
        width: 50%;
      }
      @media @wide {
        width: 33.33%;
      }
    }
  }

  &.wide--full.wide--full {
    @media @wide {
      > * {
        width: 100%;
      }
    }
  }

  &.reverse {
    > * {
      @media @tablet {
        float: right;
      }
    }
  }

  &.center-items {
    display: flex;
    justify-content: center;
  }
}

.one-half,
.two-third,
.one-third,
.three-fourth,
.one-fourth,
.one-fifth,
.two-fifth,
.one-sixth,
.one-ninth,
.three-fifth,
.three-tenth,
.seven-tenth,
.one-full {
  float: left;
  display: block;
  width: 100%;
  padding-left: 30px;
}

.one-full {
  width: 100%;
}

.one-half {
  @media @tablet {
    width: 50%;
  }
}

.two-third {
  @media @tablet {
    width: 66.66%;
  }
}

.one-third {
  @media @tablet {
    width: 33.33%;
  }

  &.tablet-full.tablet-full {
    @media @tablet {
      width: 100%;
    }
    @media @wide {
      width: 33.33%;
    }
  }
}

.three-fourth {
  @media @tabletMini {
    width: 50%;
  }
  @media @tablet {
    width: 75%;
  }
}

.one-fourth {
  @media @tabletMini {
    width: 50%;
  }
  @media @tablet {
    width: 25%;
  }
}

.one-fifth {
  width: 100%;
  @media @tablet {
    width: 20%;
  }
}

.two-fifth {
  width: 100%;
  @media @tablet {
    width: 40%;
  }
}

.three-fifth {
  width: 100%;
  @media @tablet {
    width: 60%;
  }
}

.one-sixth {
  width: 100%;
  @media @tablet {
    width: 33.33%;
  }
  @media @desktop {
    width: 16.66%;
  }
}

.one-ninth {
  width: 100%;
  @media @tablet {
    width: 11.11%;
  }
}

.three-tenth {
  @media @tablet {
    width: 30%;
  }
}

.seven-tenth {
  @media @tablet {
    width: 70%;
  }
}

.row.home-grid {
  background-color: @white;

  @media @tablet {
    padding-bottom: 40px;
  }
  @media @desktop {
    padding-bottom: 0;
  }

  .one-third {
    width: 100%;
    @media @desktop {
      width: 50%;
    }
    @media @wide {
      width: 33.33%;
    }
  }

  > .two-third {
    width: 100%;
    @media @desktop {
      width: 50%;
    }
    @media @wide {
      width: 66.66%;
    }

    &.first {
      .one-half {
        width: 100%;
        @media @tablet {
          width: 50%;
        }
        @media @desktop {
          width: 100%;
        }
        @media @wide {
          width: 50%;
        }
      }

      @media @desktop {
        width: 50%;
        .visual-wrapper {
          .visual {
            padding-bottom: 41%;
          }
        }
      }
      @media @wide {
        width: 66.66%;
        .visual-wrapper {
          .visual {
            padding-bottom: 56.25%;
          }
        }
      }

      &:after {
        @media @desktop {
          content: "";
          display: none;
          position: absolute;
          height: 100%;
          top: 0;
          left: 100%;
          width: 30px;
          background-color: @white;
        }
        @media @wide {
          width: 32px;
          margin-left: -2px;
        }
      }
    }

    &.last {
      width: 100%;
      @media @wide {
        width: 66.66%;
      }
    }
  }

  .shop {
    display: none;
    @media @wide {
      display: block;
    }
  }

  .shop--mobile {
    display: block;
    margin-bottom: 30px;

    .shop-slider {
      .item {
        padding-bottom: 100%;
        @media @tablet {
          padding-bottom: 40%;
        }
      }
    }

    @media @wide {
      display: none;
    }
  }

  .mb60 {
    margin-bottom: 30px;
    @media @wide {
      margin-bottom: 60px;
    }
  }
}

.row--gutter {
  margin-left: -30px !important;
  margin-bottom: -30px !important;

  > .row__item {
    padding-left: 30px;
    margin-bottom: 30px;
  }
}

.row--gutter-half {
  margin-left: -15px !important;
  margin-bottom: -15px !important;

  > .row__item {
    padding-left: 15px;
    margin-bottom: 15px;
  }
}

.row--gutter-third {
  margin-left: -10px !important;
  margin-bottom: -10px !important;

  > .row__item {
    padding-left: 10px;
    margin-bottom: 10px;
  }
}

.row--gutter-mb0 {
  margin-left: -30px !important;

  > .row__item {
    padding-left: 30px;
    margin-bottom: 30px;
  }
}

.row--flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -30px;

  &:after,
  &:before {
    display: none;
  }
}

.row--border {
  > .row__item {
    position: relative;

    &:first-of-type {
      padding-right: 0;
      padding-bottom: 30px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 30px;
        height: 1px;
        border-bottom: 2px dashed @accentLight;
        left: 30px;
        width: ~"calc(100% - 30px)";
      }

      @media @tablet {
        // border-bottom: 0;
        // border-right: 2px dashed @accentLight;
        // padding-right: 30px;
        // padding-bottom: 0;
        // &:after {
        //   content: "";
        //   display: none;
        // }
      }
      // @media @wide {
      //   border-right: 2px dashed @accentLight;
      // }
    }
  }

  &.row--border-right {
    > .row__item {
      &:first-of-type {
        padding-bottom: 0;

        &:after {
          left: auto;
          right: -15px;
          width: 1px;
          border-right: 2px dashed @accentLight;
          top: 0;
          height: 100%;
        }
      }

      @media @tablet {
        &:after {
          content: "";
          display: none;
        }
      }
    }
  }
}

.row__item {
  padding-left: 30px;
}

.row__item--auto-height {
  > * {
    height: 100%;
  }
}

.row__item--v-center {
  display: flex;
  align-items: center;
}

.row--column {
  display: flex;
  flex-direction: column;

  &:after,
  &:before {
    display: none;
  }
}

.row--justify-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &:after,
  &:before {
    display: none;
  }
}

.row--no-wrap {
  flex-wrap: nowrap;
}

.row--align-center {
  @media @tablet {
    display: flex;
    align-items: center;
  }
}

.medium--one-whole {
  width: 50%;
  @media @tabletMini {
    width: 50%;
  }
  @media @tablet {
    width: 100%;
  }
}
