.live-game {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: @white;
}

.live-game--light {
  color: @accent;
  background-color: @white;

  i {
    color: currentColor;
  }

  .live-game__score {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.live-game__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  column-gap: 60px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.35);

  @media @wide {
    flex-wrap: nowrap;
  }
}

.live-game__team-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media @tablet {
    gap: 20px;
  }
}

.live-game__score {
  position: relative;
  border: 1px solid @white;
  padding: 0.9em 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-height: 44px;
  min-width: 88px;
  font-weight: 600;
  .fs(16);

  @media @tablet {
    padding: 1em 2em;
    .fs(20);
  }
}

.live-game__league {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;

  @media @wide {
    margin-bottom: 0;
  }
}

.live-game__league-content {
  .live-game__league-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  & [class^="h"] {
    margin-bottom: 0.2em;
    text-align: center;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.live-game__league-logo {
  display: block;
  flex-shrink: 0;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// ugly override
.live-game__status {
  margin-bottom: 0.4em;
  position: absolute;
  background: @accent;
  color: @white;
  text-align: center;
  .fs(12);
  min-width: 6ch;
  padding: 0.2em 0.6em;
  left: auto;
  right: auto;
  margin: 0 auto;
  bottom: 0;
  font-weight: 600;
  transform: translateY(50%);
}

.live-game__overtime {
  position: relative;
  display: inline-block;
  transform: translateY(-50%);
  .fs(11);
  line-height: 1;
  color: @error;
}

.live-game__content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;

  + .button {
    margin-top: 30px;
  }

  @media @tablet {
    gap: 30px;
  }
}

.live-game__team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 0.6em;
  flex: 1;
}

.live-game__team--right {
  .live-game__team-info {
    justify-content: flex-end;
  }

  .live-game__team-actions {
    text-align: right;
  }
}

.live-game__team-logo {
  display: block;
  width: 30px;
  position: relative;
  padding-top: 30px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: block;
    object-fit: contain;
    object-position: left center;
    max-width: 100%;
    max-height: 100%;
  }
  @media @tablet {
    width: 50px;
    padding-top: 50px;
  }
}

// ugly override
.live-game__team-title.h2 {
  margin: 0;
  line-height: 0.8;
}

.live-game__team-actions {
  position: relative;
  color: @accentLight;
  width: 100%;

  i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    display: inline-block;
    line-height: 1;
  }
}
