.social-list {
  display: flex;
  gap: 10px;
  margin: 0;
  list-style: none;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    color: @accent;
  }

  a:hover,
  a:focus,
  a:active {
    color: @accent;
  }
}

.social-list--white {
  a {
    color: @white;
  }

  a:hover,
  a:focus,
  a:active {
    color: @white;
  }
}

.social-list--vertical {
  flex-direction: column;
}

.social-list--vertical-large {
  flex-direction: row;

  @media @netbook {
    flex-direction: column;
  }
}

.social-list--home {
  gap: 15px;

  @media @netbook {
    position: fixed;
    top: 14%;
  }

  @media @wide {
    top: 19%;
  }
}
