.accordion.--open .game-row {
  .icon-arrow {
    transform: scaleY(-1);
  }
}

.game-group {
  &:last-child {
    margin-bottom: 80px;
  }
}

.game-group__title {
  text-transform: capitalize;
}

.game-row {
  padding: 15px;
  background-color: @white;
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 15px;

  @media @desktop {
    padding: 15px 30px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr 1fr;
  }
}

.game-row__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @media @desktop {
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
  }
}

.game-row__league {
  // margin-right: 30px;
}

.game-row__date {
  .fs(14);
  font-weight: 700;
  text-transform: uppercase;

  br {
    display: none;

    @media @desktop {
      display: block;
    }
  }
}

.game-row__opponents {
  display: flex;
  align-items: center;
  gap: 15px;

  @media @desktop {
    gap: 30px;
  }
}

.game-row__home,
.game-row__away {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  .fs(14);
  font-weight: 700;
  hyphens: auto;

  @media @desktop {
    flex-wrap: nowrap;
    .fs(18);
    gap: 15px;
  }

  img {
    object-fit: contain;
    width: 45px;
    height: 45px;

    @media @desktop {
      width: 70px;
      height: 70px;
    }
  }
}

.game-row__home {
  flex-direction: row-reverse;
  text-align: right;
}

.game-row__score,
.game-row__separator {
  text-align: center;
  .fs(30);
  font-weight: 900;

  @media @desktop {
    .fs(38);
  }
}

.game-row__score {
  text-wrap: nowrap;
}

.game-row__away {
  // for ref
}

.game-row__actions {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;

  .button-group {
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 0;
  }

  .button {
    width: 100%;
    color: @white;
    background-color: @accentLight;
    text-align: center;

    @media @desktop {
      width: auto;
    }
  }

  @media @desktop {
    margin: 0;
  }
}

.game-row__trigger {
  width: 100%;
  color: @accent;
  border: transparent;
  background-color: transparent;
  padding: 20px 15px;

  &:hover,
  &:focus,
  &:active {
    color: @accent;
    border-color: transparent;
    background-color: transparent;
  }

  .icon-arrow {
    width: 12px;
    margin-left: 5px;
    fill: @accent;
    transform: scaleY(1);
    transition: transform 0.25s ease-in-out;
  }

  @media @desktop {
    width: auto;
    padding: 15px;
  }
}

.game-row__content {
  padding: 30px;
  background-color: @grayLight;

  @media @desktop {
    padding: 30px 25% 60px;
    padding-left: calc(~"7% + 15px");
  }
}
