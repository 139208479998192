.league-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.league-overview__item {
  padding: 2em;
  background: @accent;
  color: @white;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .info-block {
    width: 100%;
  }

  .ranking {
    .club-icon {
      display: none;
    }
  }

  @media @tablet {
    flex-direction: row;
  }
}

.league-overview__next-game,
.league-overview__calendar {
  width: 100%;

  @media @tablet {
    width: 50%;
  }

  @media @desktop {
    margin-top: 6px;
  }
}

.league-overview__calendar-list {
  list-style: none;
  margin: 0;

  li {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    + li {
      margin-top: 20px;
    }
  }
}

.league-overview__item--accent-secondary {
  background: @accentSecondary;
  color: @accent;
}

.league-overview__item--third {
  width: 100%;
  flex: unset;

  @media @desktop {
    width: 35%;
  }
}

.calendar-item {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.calendar-item__info {
  margin-top: 0.2em;

  .h3 {
    margin-bottom: 0;
  }
}

.calendar-item__logos {
  display: flex;
  flex-direction: column;
  gap: 10px;

  img {
    width: 50px;
  }
}
