.section {
  position: relative;

  &.section--skew {
    overflow: hidden;
    padding-bottom: 80px;
    &:before {
      content: "";
      width: 100%;
      height: calc(100% + 20vw);
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 25vw;
      display: block;
      pointer-events: none;
      transform: skewY(-5deg);
      background: radial-gradient(#014694 0%, #003168 100%);

      @media @mobile {
        transform: skewY(-10deg);
        top: 20vh;
      }

      @media @desktop {
        top: 8%;
      }
      @media @wide {
        top: 10%;
      }
    }

    h2,
    .h2,
    .h1,
    .h4,
    p,
    .button {
      color: @white;
    }

    + .section {
      margin-top: 0;
      padding-top: 90px;

      &.section--light {
        margin-top: 0;
      }
    }

    .post-item {
      .title-stripper {
        * {
          color: @white;
        }
      }
    }
  }

  + .section {
    margin-top: 80px;

    &.section--no-mt {
      margin-top: 0;
    }

    @media @mobile {
      margin-top: 50px;
    }
  }
}

.section--top {
  padding-top: 80px;

  @media @desktop {
    padding-top: 1.875vw;
  }

  &.section--skew {
    padding-top: 0;

    @media @tablet {
      padding-top: 80px;
    }
  }

  @media @mobile {
    padding-top: 50px;
  }
}

.section--light {
  background: @accentLighter;
  padding: 80px 0;

  @media @mobile {
    padding: 50px 0;
  }
}

&.section--light-transition {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    right: 0;
    height: 30%;
    background-color: @accentLighter;
    z-index: -1;
  }
}

.section--dark {
  background: @accent;
  color: @white;
  padding: 80px 0;

  h2,
  .h2,
  h3,
  .h3 {
    color: @white;
  }

  @media @mobile {
    padding: 50px 0;
  }
}

.section--no-p {
  padding: 0;
}

.section--no-pb {
  padding-bottom: 0;
}

.section--overflow-h {
  overflow: hidden;
}

.section--pattern {
  position: relative;
  color: @white;
  padding: 30px 0 80px;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 80vh;
    width: 100%;
    background-image: url("../images/pattern.jpg");
    background-size: cover;
    z-index: -1;

    @media @tablet {
      width: 65vw;
      height: 70vh;
    }
  }

  @media @tablet {
    min-height: 70vh;
    padding: 60px 0 80px;
  }
}

.section--latest-news {
  .section__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -30px;

    h2 {
      width: 100%;
    }

    & > * {
      flex-shrink: 0;
      padding-left: 30px;
    }

    .section-inner__news {
      @media @desktop {
        width: 67%;
      }
    }

    .section-inner__blocks {
      @media @desktop {
        width: 33%;
      }
    }

    @media @desktop {
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}

// Partial rework - different naming to ensure there are no breaking changes
.section--accent {
  padding: 40px 0;
  background-color: @accent;
  color: @white;

  &.section--no-p {
    padding: 0;
  }

  .games-slider__item {
    color: @fontColor;
  }
}

.section--medium-accent {
  padding: 40px 0;

  @media @tablet {
    background-color: @accent;
    color: @white;
  }

  &.section--no-p {
    padding: 0;
  }

  .games-slider__item {
    color: @fontColor;
  }
}

.section--decorate-bottom-light {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @accentLighter;
    height: 25%;
  }
}

.section--mt-0,
.section + .section--mt-0 {
  margin-top: 0;
}
