.cookie-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 41;
  opacity: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.58, 1);
  font-size: 14px;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  button {
    float: none !important;
    padding: 10px 15px;
    .fs(20);

    &.button--minimal {
      color: @fontColor;
      text-decoration: underline;
      .fs(20);
      font-weight: 400;

      &:hover,
      &:focus,
      &:active {
        color: @fontColorHover !important;
      }
    }
  }

  @media (min-width: 768px) {
    bottom: 30px;
    left: 30px;
    right: inherit;
    width: 520px;
  }
}

.cookie-notification__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.cookie-notification__content {
  background-color: #f7fafc;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  padding: 30px;
  max-height: 100vh;

  .button-group {
    a {
      color: #4a5568;

      &:hover,
      &:focus {
        color: #2d3748;
      }
    }
  }

  @media (min-width: 768px) {
    max-height: ~"calc(100vh - 30px * 2)";
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    flex-wrap: wrap;
  }
}

.cookie-notification__form {
  margin: 30px 0 0;

  .button {
    margin-bottom: 15px;
  }

  .input-group {
    margin-bottom: 15px;
  }

  .checkbox {
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      font-size: 14px;
    }
  }
}

.cookie-notification__actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px 20px;
}
.cookie-notification__form-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}

.show {
  display: block !important;
  opacity: 1 !important;
  pointer-events: all;
}
