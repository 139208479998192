.gsc-search-box .gsc-search-button-v2 {
  height: 100%;
  min-width: 50px;
  width: 125px;
  padding: 20px 30px;
  margin-top: 0;
}

.gsc-input-box {
  height: 55px;
  padding: 13px 15px;
}

.gsc-input-box td {
  padding: 0;
}

.gsc-table-result .gs-web-image-box {
  margin-right: 60px;
}

.gsc-table-result .gs-web-image-box .gs-image {
  max-width: 100px;
}

.gsc-table-result .gsc-table-cell-snippet-close {
  padding-right: 10px;
}

.gsc-results .gsc-result {
  margin-bottom: 15px;
}