/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, core, patterns, plugins
 *
 * LAYOUT
 * Grid / Elements (icons, slideshow, other, ...)
 * Header / Main / Footer
 * Content elements (no overrides from main)
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 *
 * CLASSES
 * Naming: lorem-ipsum
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media @mobile, @tabletMini, @tablet, @desktop, @netbook, @wide ...
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

/* CSS Reset */
@import "inc/reset.less";

/* Core */
@import "inc/vars.less";
@import "inc/mixins.less";
@import "inc/section.less";
@import "inc/revealing-list.less";
@import "inc/preview-item.less";
@import "inc/modals.less";
@import "inc/dropdown.less";
@import "inc/cookie-notification.less";
@import "inc/product-tile.less";
@import "inc/tab-navigation.less";
@import "inc/game-row.less";
@import "inc/note.less";
@import "inc/info-card.less";
@import "inc/live-game.less";
@import "inc/game-action.less";
@import "inc/social-list.less";
@import "inc/accordion.less";
@import "inc/league-overview.less";
@import "inc/google-search-engine.less";

//@import "inc/custom-animations.less";

:root {
  --swiper-theme-color: @accentSecondary !important;
}

/* Init mediaqueries */
.init-mediaqueries {
  @media @tabletMini {
    margin: 0;
  }
  @media @tablet {
    margin: 0;
  }
  @media @mobile {
    margin: 0;
  }
  @media @desktop {
    margin: 0;
  }
  @media @netbook {
    margin: 0;
  }
  @media @wide {
    margin: 0;
  }
  @media @xwide {
    margin: 0;
  }
}

/* Styled patterns and elements */
@import "inc/type.less";
@import "inc/tables.less";
@import "inc/forms.less";
@import "inc/footer.less";
@import "inc/grid.less";
@import "inc/multi-info-block.less";
@import "inc/visual.less";

/* Plugins */
@import "inc/plugins.less";
/* TODO: rework */
@import "inc/sso.less";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html,
body {
  @media @tablet {
    height: 100%;
  }
}

html {
  font-size: 62.5%;
  overflow-x: hidden;

  @media @tablet {
    font-size: 70%;
  }
}

body {
  #font > .default;
  color: @fontColor;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media @tablet {
    height: auto;
    min-height: auto;
    z-index: 2;
  }
}

/* typekit async loading (all elements using @ typekit font) */
.wf-loading {
  overflow: hidden;

  body {
    visibility: hidden;
  }
}

@selection: @accentHover;
.selection(rgba(red(@selection), green(@selection), blue(@selection), 0.2), inherit);

/* =============================================================================
   ELEMENTS
   ========================================================================== */
.icon-x {
  width: 17px;
  height: 17px;

  img {
    display: block;
    width: 80%;
    height: auto;
  }
}

/* ICONS */
.icon-tiktok:before {
  content: "\e91b";
}

.icon-profile:before {
  content: "\e917";
}

.icon-globe:before {
  content: "\e918";
}

.icon-play:before {
  content: "\e91a";
}

.icon-ball:before {
  content: "\e913";
}

.icon-triangle-right:before {
  content: "\e90b";
}

.icon-chevron-right:before {
  content: "\e907";
}

.icon-chevron-left:before {
  content: "\e908";
}

.icon-chevron-down:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e919";
}

.icon-search:before {
  content: "\e916";
}

.icon-stopwatch:before {
  content: "\e914";
}

.icon-video-camera:before {
  content: "\e90d";
}

.icon-camera:before {
  content: "\e90e";
}

.icon-arrow-right:before {
  content: "\e90a";
}

.icon-instagram:before {
  content: "\e900";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-google-plus:before {
  content: "\e901";
}

.icon-linkedin:before {
  content: "\e902";
}

.icon-facebook:before {
  content: "\e903";
}

.icon-twitter:before {
  content: "\e904";
}

.icon-arrow-left:before {
  content: "\e905";
}

.icon-triangle-down:before {
  content: "\e906";
}

.icon-whatsapp:before {
  content: "\e915";
}

.icon-calendar:before {
  content: "\e90f";
}

.icon-bicycle:before {
  content: "\e910";
}

.icon-bus:before {
  content: "\e911";
}

.icon-car:before {
  content: "\e912";
}

.icon-ticket:before {
  content: "\e90c";
}

.icon-arrow-left,
.icon-arrow-right {
  .fs(8);
}

//CUSTOM ICONS
.icon-changeplayer {
  .icon-chevron-right,
  .icon-chevron-left {
    color: #990000;
  }

  .icon-chevron-left {
    color: #336600;
  }
}

.icon-card-red,
.icon-card-yellow {
  width: 15px;
  height: 21px;
  display: inline-block;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
  background: #ffcc00; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #ffcc00 0%,
    #ffee00 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #ffcc00 0%,
    #ffee00 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #ffcc00 0%,
    #ffee00 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFCC00', endColorstr='#FFEE00',GradientType=1); /* IE6-9 */
}

.icon-card-red {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
  background: #b00000; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #b00000 0%,
    #cc3300 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #b00000 0%,
    #cc3300 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #b00000 0%,
    #cc3300 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#B00000', endColorstr='#FFEE00',GradientType=1); /* IE6-9 */
}

.card {
  .icon-card-red,
  .icon-card-yellow {
    height: 18px;
    width: 12px;
    position: relative;
    top: 2px;
  }
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  height: 1px;
  border-top: 2px solid @white;
  .transition(0.2s);
}

.icon-hamburger {
  position: relative;

  &:before,
  &:after {
    content: "";
    left: 0;
    margin-left: 0;
  }

  &:before {
    margin-top: 4px;
  }

  &:after {
    margin-top: 10px;
  }
}

/* HELPERS */
.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.a-c {
  text-align: center;
}

.a-l {
  text-align: left;
}

.a-r {
  text-align: right;
}

.desktop-a-l {
  text-align: left;
}

.desktop-a-c {
  text-align: center;
}

.desktop-a-r {
  text-align: right;
}

.left {
  float: left;
}

.right {
  float: right;
}

.float-left.float-left {
  float: left;
  clear: none;
}

.float-right.float-right {
  float: right;
  clear: none;
}

.tt {
  text-transform: uppercase;
}

.dis-b {
  display: block;
}

.dis-ib {
  display: inline-block;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.max500 {
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.max700 {
  display: block;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.mobile-mb20.mobile-mb20 {
  @media @mobile {
    margin-bottom: 20px;
  }
}

.mb0.mb0 {
  margin-bottom: 0;
}

.mb6.mb6 {
  margin-bottom: 6px;
}

.mb10.mb10 {
  margin-bottom: 10px;
}

.mb20.mb20 {
  margin-bottom: 20px;
}

.mb30.mb30 {
  margin-bottom: 30px;
}

.mb40.mb40,
.mb50.mb50,
.mb60.mb60,
.mb80.mb80,
.mb100.mb100 {
  margin-bottom: 40px;
}

@media @tablet {
  .mb50.mb50 {
    margin-bottom: 50px;
  }

  .mb60.mb60 {
    margin-bottom: 60px;
  }

  .mb80.mb80 {
    margin-bottom: 80px;
  }

  .mb100.mb100 {
    margin-bottom: 100px;
  }
}

.mt15 {
  margin-top: 15px;
}

.mt30 {
  margin-top: 30px;
}

.mt60 {
  margin-top: 60px;
}

.mt35-neg {
  margin-top: -35px;
}

.mx-auto.mx-auto {
  margin: 0 auto;
}

.mobile-show {
  display: block;
  @media @tablet {
    display: none;
  }
}

.mobile-show-inline {
  display: inline;
  @media @tablet {
    display: none;
  }
}

.mobile-hide {
  display: none;
  @media @tablet {
    display: block;
  }
}

.mobile-hide-table-cell {
  display: none;
  @media @tablet {
    display: table-cell;
  }
}

.mobile-hide-default {
  display: none;
  @media @tablet {
    display: inherit;
  }
}

.tablet-hide-table-cell {
  display: table-cell;
  @media @tablet {
    display: none;
  }
}

.tablet-show {
  display: none;
  @media @tablet {
    display: block;
  }
}

.tablet-hide {
  display: block;
  @media @tablet {
    display: none;
  }
}

.desktop-hide {
  display: block;
  width: 100%;

  @media @desktop {
    display: none;
  }
}

.desktop-show {
  display: none;

  @media @desktop {
    display: block;
    width: 100%;
  }
}

/* SLIDESHOW */
.cycle-slideshow {
  width: 100%;
  position: relative;
  overflow: hidden;

  &.news-slider {
    position: relative;

    .slide {
      background-color: @white;
    }
  }

  &.hero-slider {
    z-index: 0;
    // height: 100%;
  }
}

.visual-pager {
  position: absolute;
  right: 0;
  top: 0px;

  .cycle-arrow {
    position: relative;
    float: left;
    top: 0;
    .transform(translateY(0));
    background-color: @white;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    .fs(20);
    .transition;

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 1);
    }

    &.prev {
      left: 0px;
    }

    &.next {
      right: 0px;
    }
  }
}

.cycle-pager {
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  z-index: 1000;

  span {
    border: 2px solid #fff;
    .border-radius(20px);
    height: 15px;
    width: 15px;
    display: inline-block;
    cursor: pointer;
    margin: 0 3px;
    line-height: 14px;
    font-size: 36px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    .transition(0.2s);
    background-color: @accent;
    color: @accent;

    &:hover,
    &:focus {
      .transition(none);
      background-color: @accentLight;
      color: @accentLight;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
    background-color: @accentLight;
    color: @accentLight;
  }
}

.cycle-arrow {
  position: absolute;
  top: 50%;
  z-index: 1000;
  .transform(translateY(-50%));
  cursor: pointer;

  &:after {
    font-family: "icomoon";
    .transition;
  }

  &.prev {
    left: 20px;

    &:after {
      content: "\e908";
    }
  }

  &.next {
    right: 20px;

    &:after {
      content: "\e907";
    }
  }

  &:hover,
  &:focus {
    &:after {
      color: @accentLight;
    }
  }

  &.large {
    .fs(30);
  }
}

.slide {
  position: relative;
  width: 100%;
}

/* OTHER */

/* google maps mw */
.gm-style img {
  max-width: inherit;
}

/* responsive video */
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.facebook-video-wrapper {
  min-height: 350px;
  background: @black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media @tablet {
    min-height: 500px;
  }
}

/* equal height */
.equal-h {
  .flex-box;
  .flex-box-wrap;

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;

    > div {
      float: left;
    }
  }
}

/* vertical align */
.va-wrap,
.va-m,
.va-b {
  display: table;
  width: 100%;
  height: 100%;
}

.va-wrap {
  table-layout: fixed;
}

.va-m,
.va-b {
  display: table-cell;
}

.va-m {
  vertical-align: middle;
}

.va-b {
  vertical-align: bottom;
}

.va-abs-m {
  position: absolute;
  top: 50%;
  .transform(translateY(-50%));
}

/* blocklink */
.blocklink {
  cursor: pointer;
}

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header-banner {
  width: 100%;
  text-decoration: none;
  padding: 15px;
  background-color: @accent;
}

.page-header {
  position: sticky;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: @white;
}

.page-header__left,
.page-header__right {
  width: 45%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-header__wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page-header__wrap--mobile {
  align-items: center;
  padding: 0 0 0 15px;

  @media @desktop {
    display: none;
  }
}

.page-header__wrap--desktop {
  display: none;

  @media @desktop {
    display: flex;
    padding: 0;
  }
}

.page-header__logo {
  position: relative;
  top: -46px;
  display: flex;
  align-items: center;
  z-index: 9;
  display: none;

  @media @desktop {
    top: -60px;
    display: block;
  }
}

.mobile-tickets {
  margin-left: auto;
}

.mobile-tickets,
.mobile-webshop {
  border-left: 1px solid rgba(255, 255, 255, 0.2);

  a {
    padding: 22px !important;
  }

  i {
    .fs(22);
  }
}

.mobile-account-trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 22px 0 15px;
  justify-content: flex-end;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;

  a,
  .account {
    padding: 0 !important;
    color: @white;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background: none !important;

    &:hover,
    &:focus,
    &:active {
      background: none !important;
      background-color: transparent;
    }
  }

  .dropdown__list {
    top: ~"calc(100% + 0.8em)";
  }

  .dropdown__link {
    padding: 5px 0 5px 25px !important;
  }

  .dropdown__list--right:before {
    right: 10px;
  }

  .dropdown__list--right:after {
    right: 0;
  }

  span + i,
  i + span {
    margin-left: 10px;
  }

  span,
  i {
    text-transform: none;
    color: @white;
  }

  .icon-profile {
    .fs(22);
  }

  @media @desktop {
    display: none;
  }
}

.page-header__logo--mobile {
  width: 55px;
  height: 55px;
  z-index: 9;
  display: block;
  top: auto;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  a {
    background: none !important;
    background-color: transparent;

    &:hover,
    &:focus {
      background: none !important;
      background-color: transparent;
    }
  }

  @media @desktop {
    display: none;
  }
}

a.page-header__help-center {
  padding: 0 !important;
}

.top-banner {
  // background-color: @accent;
  color: @accent;
  position: relative;
  z-index: 5;
  padding: 0;

  .fs(12);
  @media @tablet {
    .fs(11);
  }
  @media @desktop {
    .fs(12);
  }
  @media @wide {
    .fs(14);
  }

  .icon-search {
    font-size: 8px;
    padding: 0px 5px;
    @media @desktop {
      font-size: 10px;
      padding: 0px 0px;
    }
    @media @wide {
      font-size: 11px;
    }
  }

  .lang-selector {
    @media @mobile {
      a {
        padding-top: 34px;
        padding-bottom: 34px;
      }
    }
  }

  .one-half.one-half {
    &:first-child {
      width: 70%;
      @media @tablet {
        width: 50%;
      }
    }

    &:last-child {
      width: 30%;
      @media @tablet {
        width: 50%;
      }
    }
  }
}

.subnav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.secondary-nav {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  border-bottom: 1px solid @accentGray;

  li:not(.lang-list__item) {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      height: 50%;
      width: 1px;
      background-color: @accentGray;
      .transition(0.2s);
    }

    &:first-child::before {
      content: none;
    }

    .icon-accent,
    .icon-white {
      .transition(0.2s);
    }

    .icon-accent {
      position: absolute;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &.active {
      &::before {
        background-color: @accentHover;
      }

      &:not(.lang-list__item) > a,
      .account {
        color: @white;
        background-color: @accentHover;

        .icon-accent {
          opacity: 0;
        }
      }
    }
  }

  li:not(.lang-list__item) > a,
  .account {
    height: 100%;
    color: @accent;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 18px;
    .fs(18);

    @media @desktop {
      .fs(20);
    }
    @media @wide {
      padding: 15px 15px;
      .fs(20);
    }

    @media @xwide {
      padding: 20px 25px;
      .fs(20);
    }
  }

  i {
    .fs(22);
  }

  span + i,
  i + span,
  span + img,
  img + span {
    margin-left: 10px;
  }

  .subnav__profile {
    padding: 0 15px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-search {
    font-size: 8px;
    padding: 0px 5px;

    @media @desktop {
      font-size: 18px;
      padding: 0px 0px;
    }

    @media @wide {
      font-size: 20px;
    }
  }

  @media @desktop {
    display: flex;
  }
}

.header-content {
  z-index: unset;
  display: flex;

  @media @mobile {
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @media @tablet {
    margin: 0;
  }

  @media @desktop {
    margin: 0 auto;
  }
}

.header-bottom {
  padding: 0.5em 0;
  background: @accent;
  color: white;
  text-align: center;
  text-decoration: none;
  display: block;
}

.extra-nav-item {
  @media @mobile {
    position: absolute;
    right: 0;
    top: 100%;
    a {
      background-color: @accentLight;
      color: @white;

      &:hover,
      &:focus {
        background-color: @accentHover;
      }
    }
  }

  &.mobile {
    a {
      padding: 16px 18px 15px;
    }

    display: block;
    @media @tablet {
      display: none;
    }
  }

  &.desktop {
    display: none;
    @media @tablet {
      display: block;
    }
  }

  &.extra-nav-item--square {
    a {
      padding: 0 19px;
      background: #08519e;
      height: 100%;

      .icon {
        .fs(15);
      }
    }
  }
}

.inline-lang-list {
  li {
    position: relative;

    a {
      &.lang-flag {
        padding: 12px 5px 12px 29px;
        @media @desktop {
          padding: 15px;
        }

        &:before {
          left: 7px;
          @media @desktop {
            left: 12px;
          }
        }
      }
    }
  }

  @media @mobile {
    display: none;
  }
}

.lang-selector {
  position: relative;
  cursor: pointer;
  background-color: @white;
  .transition();

  .lang-trigger {
    padding: 0 18px;
    height: 100%;
    display: flex;
    align-items: center;

    .icon-globe {
      .fs(19);
      .transition();
      color: @accent;
    }

    a {
      @media @mobile {
        padding: 34px 18px 34px 40px;
      }

      &:hover,
      &:focus {
        background-color: @accentHover;
      }
    }
  }

  .lang-list {
    display: none;
    list-style: none;
    position: absolute;
    background-color: @white;
    top: 100%;
    margin: 0;
    right: 0;
    width: 100%;
  }

  .lang-list__item {
    float: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    a {
      background-color: @white;
      color: @accent;
      position: relative;
      width: 100%;

      &.lang-flag {
        padding: 15px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 18px;
          top: 50%;
          .transform(translateY(-50%));
          float: left;
          width: 16px;
          height: 11px;
          margin-right: 6px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      &.lang-nl {
        &:before {
          background-image: url("../images/lang/nl.svg");
        }
      }

      &.lang-fr {
        &:before {
          background-image: url("../images/lang/fr.svg");
        }
      }

      &.lang-en {
        &:before {
          background-image: url("../images/lang/en.svg");
        }
      }

      &.disabled {
        pointer-events: none;
      }

      &:hover,
      &:focus {
        background-color: @accent;
        color: @white;
      }

      @media @mobile {
        padding-top: 34px;
        padding-bottom: 34px;
      }
    }
  }

  &:hover,
  &:focus-within {
    background-color: @accent;

    .icon-globe {
      color: @white;
    }
  }
}

.logo {
  position: relative;
  height: 55px;
  padding: 0;
  display: block;
  text-decoration: none;
  .opacity(100);
  z-index: 100;
  .clearfix;
  display: block;
  margin: 0 auto;

  img {
    display: block;
    height: 100%;
    object-fit: contain;

    @media @tablet {
      height: 55px;
    }

    @media @desktop {
      height: 100px;
    }

    @media @wide {
      height: 130px;
    }
  }

  &:hover,
  &:focus {
    .opacity(100);
  }

  @media @tablet {
    height: 30px;
  }

  @media @desktop {
    margin-top: 5px;
  }

  @media @wide {
    height: 40px;
    margin-top: -25px;
  }
}

.mobile-nav-trigger {
  display: block;

  @media @desktop {
    display: none;
  }
}

.main-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  .optimize-font;
  margin-left: auto;
  width: 100%;
  background: @accent;
  color: @white;
  #font > .heading;

  li {
    display: flex;
    align-items: center;
    cursor: default;

    &.external {
      width: 100%;

      a {
        text-align: center;
        background-color: @accentLight;
        color: @white;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: @accentHover;
        }
      }
    }

    @media @tablet {
      .fs(16);
      margin: 0;
      width: auto;

      &:nth-child(odd) {
        border-right: 0 solid @grayLight;
      }
    }

    @media @desktop {
      .fs(24);
    }

    @media @xwide {
      .fs(32);
    }
  }

  a,
  .dropdown__trigger {
    text-decoration: none;
    color: @white;
    padding: 20px 0;
    display: block;
    border: none;
    .fs(30);

    @media @tablet {
      display: inline-block;
      border: none;
      font-weight: 700;
      .fs(30);
    }

    @media @desktop {
      color: @accent;
      .fs(30);
    }

    @media @xxwide {
      .fs(35);
    }
  }

  .logo {
    padding: 0;
  }

  .trigger {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    width: 50px;

    .icon-hamburger {
      position: absolute;
      left: 14px;
      top: 8px;
    }

    @media @desktop {
      display: none;
    }
  }

  @media @desktop {
    background-color: @white;
    color: @accent;
  }
}

.main-nav .nav-wrap {
  display: none;
  margin: 0;
  #font > .heading;

  a {
    &:hover,
    &:active,
    &:focus {
      color: @accentLight;
    }
  }

  li {
    display: flex;
  }

  @media @tablet {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 0.8em;

    a {
      display: inline-flex;
      align-items: center;
    }
  }
}

.nav-wrap__content {
  padding: 50px 0;
  margin-left: 10vw;

  a {
    #font > .heading;
    padding: 3px 0;
    line-height: 1.25;

    &:hover,
    &:focus,
    &:active {
      color: @accentLight;
    }

    @media @desktop {
      .fs(18);
    }

    @media @xwide {
      .fs(23);
    }
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
}

body.dark-overlay .main {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* =============================================================================
   CONTENT
   ========================================================================== */

.large {
  .fs(16);
}

.center {
  text-align: center;
}

.center-block {
  text-align: center;
  .clearfix;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

.box {
  margin: 0 0 20px;
  padding: 15px 15px 15px;
  .clearfix;
  background: @accentLighter;

  ul,
  p {
    margin-bottom: 0px;
  }

  &.error {
    background: @error;
    color: @white;
  }

  &.success {
    background: @accent;
    color: @white;
  }
}

section {
  position: relative;
}

.section {
  &.pull-up {
    margin-top: -80px;
    @media @tablet {
      margin-top: -120px;
    }
    @media @desktop {
      margin-top: -150px;
    }
    @media @wide {
      margin-top: -200px;
    }
    @media @xwide {
      margin-top: -200px;
    }
    @media @xwide {
      margin-top: -200px;
    }
  }
}

.home-mixed-data-wrapper {
  &:after {
    content: "";
    display: block;
    background-color: @white;
    width: 30px;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
  }
}

.home-mixed-data-bg {
  /* make a blue background to wait for the background-image */
  position: relative;

  &:after {
    content: "";
    display: none;
    background-color: #2175b4;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 80px;
    height: 100%;
    width: 100%;
    @media @tablet {
      top: 120px;
    }
    @media @desktop {
      top: 150px;
      display: block;
    }
    @media @wide {
      top: 200px;
    }
  }
}

.home-mixed-data.home-mixed-data {
  position: relative;
  background-image: url("../images/home/background-light.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 80px;

  @media @tablet {
    background-position: 0 120px;
  }
  @media @desktop {
    background-position: 0 150px;
  }
  @media @wide {
    background-position: 0 200px;
  }

  position: relative;
  z-index: 2;
  padding-bottom: 0px;
  @media @desktop {
    padding-bottom: 60px;
  }

  .row {
    position: relative;
  }

  .pad30-sides {
    padding: 0px 30px;
  }

  .pad30 {
    padding: 30px;
  }

  .post-item {
    h3 {
      margin-bottom: 0;
    }

    .title-stripper {
      height: 19px;
      display: block;
      overflow: hidden;
    }
  }

  .albums {
    @media @tablet {
      display: none;
    }
    @media @wide {
      display: block;
    }
  }

  .extra-shop {
    display: none;
    @media @wide {
      display: none;
    }
  }
}

.overview-grid-media-item--exclusive,
.post-item--exclusive {
  position: relative;

  .visual {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: @black;
      opacity: 0.7;
      z-index: 2;
    }
  }

  h3,
  .h4,
  time,
  .dotdotdot {
    opacity: 0.5;
  }
}

.p30.p30 {
  padding: 30px 0px;
}

.pt30.pt30 {
  padding-top: 30px;
}

.pb30.pb30 {
  padding-bottom: 30px;
}

.tablet--pb30.tablet--pb30 {
  @media @tablet {
    padding-bottom: 30px;
  }
}

.p60.p60 {
  padding: 30px 0px;
  @media @tablet {
    padding: 60px 0px;
  }
}

.pb60.pb60 {
  padding-bottom: 30px;
  @media @tablet {
    padding-bottom: 60px;
  }
}

.pt60.pt60 {
  padding-top: 30px;
  @media @tablet {
    padding-top: 60px;
  }
}

.p90.p90 {
  padding: 30px 0px;
  @media @wide {
    padding: 90px 0px;
  }
}

.pb90.pb90 {
  padding-bottom: 30px;
  @media @tablet {
    padding-bottom: 90px;
  }
}

.pt90.pt90 {
  padding-top: 60px;
  @media @tablet {
    padding-top: 90px;
  }
}

.pad30.pad30 {
  padding: 30px;

  &.mobile-no-pad {
    @media @mobile {
      padding: 30px 0px;
    }
  }
}

.pad10 {
  padding: 10px;
}

.pad15 {
  padding: 15px;
}

.bg-white {
  background-color: @white;
}

.bg-gray {
  background-color: @grayLight;
}

.bg-accentHover {
  background-color: @accentHover;
}

.bg-accentGray {
  background-color: @accentGray;
}

.bg-grayMiddle {
  background-color: @grayMiddle;
}

.bg-accentLighter {
  background-color: @accentLighter;
}

.match-fixture {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
}

.match-fixture__sold-out {
  display: flex;
  justify-content: center;
  align-items: center;
  background: @accentLight;
  padding: 5px;
  color: @white;
  margin-bottom: 30px;
}

.match-fixture__logo {
  display: inline-block !important;
  width: 35px !important;
  margin-bottom: 0 !important;
}

.newsletter-wrap {
  border: 2px solid @accentLighter;
  height: 100%;
  padding: 40px 28px;
}

.newsletter-wrap--home {
  background-repeat: no-repeat;
  background-size: cover;
  // background-blend-mode: luminosity;
  background-position: center;
  height: auto;
  border: 1px solid fade(@accentLighter, 30%);
  margin-bottom: 30px;
  color: @white;
}

.newsfilter-row {
  .one-third {
    width: 100%;
    @media @desktop {
      width: 33.33%;
    }
  }

  .two-third {
    width: 100%;
    @media @desktop {
      width: 66.66%;
    }
  }
}

.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;
}

.route-map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 0px;
}

.interactive-map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 0px;
}

#route-instructions {
  .panel {
    display: none;

    &.active {
      display: block;
    }
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

// News ticker

.news-ticker {
  background-color: @accentLight;
  color: @white;
  height: 60px;
  overflow: hidden;

  ul {
    padding: 0px 0px;
    margin: 0;
    list-style: none;
    text-transform: uppercase;
    visibility: hidden;
    display: inline-block;

    li {
      display: inline;
      padding-left: 30px;
      line-height: 62px;
    }

    a {
      color: @white;

      &:hover,
      &:focus {
        color: @white;
        text-decoration: underline;
      }
    }
  }

  .js-marquee-wrapper {
    ul {
      visibility: visible;
    }
  }

  .js-marquee {
    float: none !important;
    display: inline-block;
  }
}

// Rotation line

.rotation {
  left: 0;
  line-height: 1.2;
  margin: 20px 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.rotation__line__wrapper {
  position: relative;
  width: 100%;
}

.rotation__line {
  display: inline-block;
  animation: marquee 50s linear infinite;
  backface-visibility: hidden;
  padding: 0 0.25rem 0 0;
  will-change: transform;
  padding-left: 0;
  margin-left: 0;
}

.rotation__line__wrapper + .rotation__line__wrapper {
  .rotation__line {
    animation-direction: reverse;
  }
}

.rotation__line + .rotation__line {
  animation: marquee-duplicate 50s -25s linear infinite;
}

.rotation:hover .rotation__line {
  animation-play-state: paused;
}

.rotation__line {
  a {
    text-decoration: none;

    transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: -5px;
      right: -5px;
      bottom: 0;
      background-color: @black;
      z-index: -99;
      transition: top 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      color: @white;

      &::before {
        top: 0;
      }
    }
  }

  li {
    display: inline-block;
    position: relative;
    margin-right: 55px;
    margin-left: 10px;
    text-align: -webkit-match-parent;
    @include fs(24);
    @include display-font();
    font-weight: 200;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -55px;
      transform: translateY(50%);
      width: 45px;
      height: 1px;
      border-bottom: 1px solid @black;
      background-color: transparent;
    }
  }
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes marquee-duplicate {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
  }
}

@keyframes marquee-duplicate {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
  }
}

@-webkit-keyframes marquee-ie {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes marquee-ie {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes marquee-duplicate-ie {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
}

@keyframes marquee-duplicate-ie {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
}

// HOME

.christmas-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, transparent, #0b2d61 80%);
  background-position: -100px 0;
}

.christmas-wrapper {
  position: relative;
  left: -30px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 33px;
    background-color: #fff;
  }

  @media @mobile {
    width: 130%;

    &:before {
      height: 54px;
    }
  }

  @media @desktop {
    &:before {
      content: none;
    }
  }
}

.christmas-bg {
  position: absolute;
  bottom: 0px;
  left: -10px;

  @media @mobile {
    bottom: 30px;
  }

  @media @desktop {
    left: -30px;
    bottom: -37px;
  }
}

.santa-wrapper {
  position: relative;
  top: -300px;
  z-index: 10;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
}

.santa-movement-y {
  position: absolute;
  width: 100%;
  top: 50px;
  animation: yAxis 14s infinite cubic-bezier(0.6, 0.71, 0.61, 0.97);
}

.santa-movement-x {
  animation: xAxis 14s infinite cubic-bezier(0.28, 0.43, 0.73, 0.75);
}

.sleigh-wrapper {
  display: block;
  width: 300px;
  height: 85px;
  position: relative;
  transform: translateX(-300px) rotate(10deg) scale(0.3);
  animation: rotate 14s infinite linear;
}

.santa {
  position: absolute;
  left: 0;
}

.rope {
  position: absolute;
  left: 33%;
  top: 60%;
}

.deer1 {
  position: absolute;
  left: 65%;
  animation: deerhop 0.5s infinite linear;
}

.deer2 {
  position: absolute;
  left: 67%;
  animation: deerhop 0.5s infinite linear;
  animation-delay: 0.4s;
}

@keyframes xAxis {
  80% {
    -animation-timing-function: linear;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes yAxis {
  0% {
    display: none;
  }

  1% {
    display: block;
  }

  40% {
    -animation-timing-function: linear;
    transform: translateY(150px);
  }

  80% {
    -animation-timing-function: linear;
    transform: translateY(10px);
  }

  99% {
    transform: translateY(10px);
    opacity: 1;
  }

  100% {
    display: none;
  }
}

@keyframes rotate {
  40% {
    -animation-timing-function: linear;
    transform: translateX(-100px) rotate(-15deg) scale(0.5);
  }

  80% {
    -animation-timing-function: linear;
    transform: translateX(0) rotate(0deg) scale(0.5);
  }

  100% {
    transform: translateX(0) rotate(0deg) scale(0.3);
  }
}

@keyframes deerhop {
  50% {
    transform: translateY(10px);
  }
}

.social__item {
  position: relative;

  .social-item__content {
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 65, 133, 0.7);
    .transition;
  }

  .social-item__body {
    display: inline-grid;
    .fs(20);
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    left: 0;
    right: 0;
    color: @white;
    text-align: center;
    padding: 0 10px;
    width: 100%;
    word-wrap: break-word;
    line-height: 140%;
    margin: 10px 0;

    span {
      white-space: pre-line;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  &:hover,
  &:focus {
    .social-item__content {
      opacity: 1;
    }

    .social-item__icons {
      z-index: -1;
    }
  }

  .visual {
    position: relative;
    width: 100%;
    height: 0;

    img,
    video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-family: "object-fit: cover; object-position: center;";
    }
  }

  .visual__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $grayLight;

    &.align-top {
      background-position: center top;
    }

    &.align-center {
      background-position: center center;
    }

    &.align-bottom {
      background-position: center bottom;
    }
  }
}
.social-grid-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;

  i {
    font-size: 0.8em;
  }
}

.social-grid {
  display: none;
  @media @tablet {
    display: block;
  }

  .grid__item {
    margin-bottom: 0;
  }

  .visual {
    padding-top: 100%;
  }
}

.swiper-instagram {
  display: block;
  @media @tablet {
    display: none;
  }

  .row__item {
    padding: 0;
  }
}

.slider-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -30px;

  .slider__prev,
  .slider__next {
    margin-left: 30px;
    cursor: pointer;

    i {
      .fs(14);
      color: @accentLight;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

.slider-navigation--end {
  justify-content: flex-end;
}

.slider-navigation--light {
  color: @white;
}

.swiper-button-disabled {
  opacity: 0.5;
}

.hero-slider {
  position: relative;
  height: 100%;
  isolation: isolate;

  .swiper-container--main {
    height: 100%;
  }

  .swiper-pagination-progressbar {
    position: absolute;
    transform: none;
    bottom: 0;
    top: unset;
  }

  .swiper-pagination.swiper-pagination-bullets {
    position: absolute;
    transform: none;
    bottom: 20px;
    z-index: 5;
  }

  &.hero-slider--full {
    .preview-item {
      .preview-item__visual {
        position: relative;
        padding-top: 40%;
        height: 0;
      }

      .preview-item__body {
        position: absolute;
      }
    }
  }

  @media @tablet {
    width: 100%;
    min-height: 700px;
    height: 560px;
    margin-left: 0;
  }

  @media @xwide {
    min-height: 808px;
  }
}

.hero-slider__thumbs {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 2;

  @media @tablet {
    bottom: 30px;
  }

  .swiper-slide {
    height: auto;
    z-index: 2;
    pointer-events: all !important;
  }
}

.hero-slider-thumb__item {
  height: 100%;

  &:after {
    content: "";
    left: 0;
    bottom: 0;
    height: 0.2em;
    position: absolute;
    background: @accent;
    display: block;
    width: 0%;
    transition: width 0s linear;
  }
}

.hero-slider-thumb-item__content {
  position: relative;
  background: @white;
  color: @accent;
  padding: 18px 24px;
  height: 100%;

  @media @tablet {
    background: @accent;
    color: @white;
  }
}

.swiper-slide-thumb-active {
  .hero-slider-thumb__item {
    &:after {
      width: 100%;
      transition: all 6.6s linear;
    }
  }

  .hero-slider-thumb-item__content {
    background: @white;
    color: @accent;
    transition: color 0.8s cubic-bezier(0, 0, 0.58, 1);

    .hero-slider-item__title {
      color: @accent;
      transition: color 0.25s cubic-bezier(0, 0, 0.58, 1);
    }

    .hero-slider-thumb-item__subtitle {
      color: @accent;
      transition: color 0.25s cubic-bezier(0, 0, 0.58, 1);
    }
  }
}

.hero-slider__item {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  color: @white;
}

.hero-slider__item--light {
  color: @accent;
  background-color: @white;
}

.hero-slider__item--center {
  @media @desktop {
    justify-content: center;
  }
}

.hero-slider-item__content {
  color: @white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 35px;

  .button.link {
    color: @white;
    text-transform: uppercase;
    font-weight: 500;
  }

  @media @tablet {
    height: 100%;
    padding-bottom: 210px;
  }
}

.hero-slider-item__title {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  color: @white;
  margin-bottom: 0;
  text-decoration: none;
  .transition(0.2s);
  hyphens: manual;
  display: block;
  width: 100%;
  .fs(25);

  @media @tablet {
    .fs(25);
  }

  @media @wide {
    .fs(30);
  }

  &.hero-slider-item__title--large {
    .fs(35);

    @media @tablet {
      .fs(50);
    }

    @media @desktop {
      .fs(60);
    }
  }
}

.hero-slider-thumb-item__title {
  margin-bottom: 0;
}

.hero-slider-item__subtitle {
  text-transform: uppercase;
  font-weight: 700;
  color: @white;
  background-color: @accentLight;
  margin-bottom: 0.6em;
  line-height: 1;
  padding: 5px 6px 4px;
  .fs(12);

  @media @tablet {
    .fs(14s);
  }

  @media @desktop {
    .fs(16);
  }
}

.hero-slider-thumb-item__subtitle {
  text-transform: uppercase;
  font-weight: 700;
  color: @accentSecondary;
  margin-bottom: 0.6em;
  line-height: 1em;
  .fs(12);

  @media @tablet {
    .fs(14);
  }

  @media @desktop {
    .fs(14);
  }

  &.hero-slider-thumb-item__subtitle--white {
    color: @white;
  }
}

.hide {
  display: none;
}

.medium--show {
  @media @tablet {
    display: block;
  }
}

.medium--hide {
  @media @tablet {
    display: none !important;
  }
}

.hero-slider-item__visual {
  display: block;
  width: 100%;
  aspect-ratio: 4/3;

  img {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
  }

  @media @tablet {
    aspect-ratio: auto;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    height: 75%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    @media @tablet {
      height: 40%;
    }
  }
}

.hero-slider-item__visual--left {
  img {
    object-position: left center;
  }
}

.hero-slider-item__link-overlay {
  text-decoration: none;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 150;
  left: 0;
  top: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  height: auto;
}

.video-slider {
  margin-bottom: 30px;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }
}

.video-slider__item {
  height: 100%;

  .visual-wrapper {
    margin-bottom: 20px;
  }

  .video-slider-item__title {
    .fs(21);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    a {
      color: @white;
    }
  }
}

.games-slider {
  margin: 0;

  .swiper-container {
    overflow: visible;
    margin-bottom: 45px;
  }

  .swiper-pagination-bullet:first-child {
    // display: none;

    @media @wide {
      display: none;
    }
  }
}

.games-slider__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: @white;
  text-align: center;
  padding: 20px 30px;
  height: 100%;
  .transition();
}

.games-slider__item-decorate-visual {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.games-slider__item--results,
.games-slider__item--calendar {
  .transition();
  .fs(22);

  strong {
    display: block;
  }

  a {
    color: @white;
    line-height: 1.5em;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media @xwide {
    .fs(25);
  }
}

.games-slider__item--image-fallback {
  background-color: @accent;
  background-image: url("../images/games-results-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  justify-content: center;

  &:hover,
  &:focus-within {
    background-color: @accentHover;
  }
}

.games-slider__item--game {
  .item__league-logo {
    display: block;
    height: 40px;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .item__game-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }

  .item__team-left,
  .item__team-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    .fs(12);
    font-weight: 400;
    line-height: 1.2em;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .item__team-logo {
    height: 48px;
    width: 48px;
  }

  .item__game-score {
    display: flex;
    justify-content: center;
    font-weight: 600;
    border-radius: 2px;
    min-height: 44px;
    min-width: 88px;
    padding: 8px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    .fs(20);
    margin-top: 10px;
  }

  .item__team-left,
  .item__team-right {
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media @tabletMini {
      margin: 0;
      order: initial;
    }
  }

  .item__league {
    .fs(12);
  }

  .item__timing {
    padding: 0 15px;

    @media @tabletMini {
      flex-basis: auto;
      order: initial;
      flex-shrink: 0;
    }

    strong,
    span {
      line-height: 1;
      display: block;
    }

    strong {
      .fs(38);
      font-weight: 900;
      padding-bottom: 8px;
      min-width: 4ch;

      @media @xwide {
        .fs(50);
      }
    }

    span {
      .fs(14);
      text-transform: uppercase;
      font-weight: 700;

      @media @xwide {
        .fs(18);
      }
    }
  }

  .item__team-names {
    .fs(18);
    font-weight: 700;
    margin-bottom: 20px;
    min-height: 54px;
  }

  .item__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: auto;
    gap: 8px;

    .button,
    a.button {
      white-space: nowrap;
      width: auto;
      flex: 1;

      i + span {
        margin-left: 8px;
      }

      @media @xwide {
        .fs(20);
      }
    }
  }
}

.in-the-picture-slider {
  .swiper-slide {
    width: auto;

    picture {
      display: block;
      width: 100%;
      height: clamp(600px, 35vw, 720px);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        .transition();
      }
    }

    a {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:hover,
    &:focus-within {
      cursor: pointer;

      img {
        transform: scale(1.05);
      }
    }
  }
}

.swiper-pagination {
  bottom: -15px;
  transform: translateY(100%);
  width: 100%;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0;
  position: relative;

  @media @tablet {
    margin-left: -10px;
  }

  &.swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    margin-left: 10px;
    background: @white;
    opacity: 1;
    width: 10px;
    height: 10px;
    cursor: pointer;

    &.swiper-pagination-bullet-active {
      background: @accentLight;
    }
  }
}

@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.hero-intro {
  position: relative;
  height: 100%;
  overflow: visible;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);

  @media @mobile {
    height: auto;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .slide {
    position: relative;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    padding-top: 100%;
    height: 0;
    background-color: @grayLight;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    @media @tablet {
      height: 100%;
      padding: 0;
    }

    &.valign-top {
      background-position: center top;
    }

    &.valign-center {
      background-position: center center;
    }

    &.valign-bottom {
      background-position: center bottom;
    }
  }

  .swiper-pagination {
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;
  }

  .swiper-pagination-bullet {
    position: relative;
    bottom: 0px !important;
    display: flex;
    flex: 1;
    flex-grow: 1;
    height: auto;
    text-align: left;
    border-radius: 0;
    opacity: 1;
    margin-right: 0px;
    background-color: transparent;
    margin: 0;

    em {
      font-size: 15px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: -0.38px;
      color: #111;
    }

    i {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 4px;
      background-color: @accentSecondary;
    }

    b {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 0%;
      height: 4px;
      background-color: hsla(0, 0%, 100%, 0.75);
    }
  }
  .swiper-pagination-bullet-active {
    background-color: transparent;

    b {
      animation-name: countingBar;
      animation-duration: 6s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
    }
  }
}

.hero-intro--game {
  box-shadow: none;
  margin-top: 50px;

  .hero-slider__item {
    height: auto;
    color: @fontColor;
  }

  .hero-slider-item__content {
    box-shadow: none;
  }

  .hero-slider-item__content {
    padding-bottom: 0;
    position: relative;

    @media @tablet {
      padding-bottom: 0;
    }
  }

  .live-game {
    padding: 30px;
  }

  .hero-slider {
    min-height: auto;
    height: auto;

    @media @tablet {
      min-height: auto;
      height: auto;
    }

    @media @desktop {
      min-height: auto;
      height: auto;
    }

    @media @wide {
      min-height: auto;
      height: auto;
    }
  }
}

.hero-sidebar {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 15px;

  @media @netbook {
    position: absolute;
    right: -30px;
    bottom: 27.5%;
    display: block;
    margin-top: 0;
  }
}

.h3.hero-intro__countdown-title-top {
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;

  @media @desktop {
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.h3.hero-intro__countdown-title-bottom {
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 12px;

  @media @desktop {
    margin-top: 40px;
    font-size: 16px;
  }
}

.hero-intro__countdown {
  z-index: 999;
  top: 35%;
  position: absolute;
  color: white;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(11, 45, 97, 0.8);
  padding: 20px;

  @media @desktop {
    padding: 60px;
  }
}

.countdown {
  display: block;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
  .fs(14);

  strong {
    font-weight: 600;
  }

  span {
    display: inline-block;
    .fs(21);
    padding-left: 4px;
    padding-right: 2px;
    font-weight: 900;
  }
}

.inline-title {
  position: relative;
  display: block;
  //display: flex;
  margin-bottom: 20px;

  .title {
    display: inline;
    margin-bottom: 0;
    //flex: 1 1 auto;
    a {
      &:hover,
      &:focus {
        color: @accentLight;
      }
    }
  }

  .sublink.sublink {
    //flex: 0 1 auto;
    float: right;
  }

  &.sublink-only-wide {
    .sublink {
      display: none;
      @media @wide {
        display: block;
      }
    }
  }
}

.slider-block {
  position: relative;
  background-color: @grayLight;

  .title {
    padding: 30px;
    text-align: center;

    p {
      margin-bottom: 0;
      @media @tablet {
        .fs(20);
      }
      @media @wide {
        .fs(26);
      }
    }
  }

  .content {
    padding: 0px 0px;
  }

  .item {
    padding-bottom: 100%;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    @media @wide {
      padding-bottom: 80%;
    }
    @media @xwide {
      padding-bottom: 100%;
    }
  }

  &.small {
    .item {
      padding-bottom: 75.5%;
    }
  }
}

.fancybox-width-fix {
  width: 100%;
  @media @tablet {
    min-width: 700px;
  }
}

.visual-wrapper {
  position: relative;
  display: block;
  overflow: hidden;

  &.no-visual {
    border-bottom: 1px solid @grayMiddle;
  }

  .visual {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center center;
    .transform(scale(1));
    .transition;

    &:hover,
    &:focus,
    &:focus-within {
      .inner-content {
        .button {
          background: @accentLightHover;
        }
      }

      .album,
      .video {
        &:before {
          .transform(scale(1.2));
        }
      }
    }
  }

  .visual .video,
  .visual .album {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      font-family: "icomoon";
      display: block;
      position: absolute;
      color: @white;
      .fs(18);
    }

    &:before {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      .border-radius(50%);
      background: @accentLight;
      opacity: 0.9;
      .transition;
      filter: drop-shadow(0px 20px 40px rgba(1, 70, 148, 0.5));

      @media @tablet {
        width: 80px;
        height: 80px;
      }
    }
  }

  .visual .video:after {
    content: "\e91a";
  }

  .visual .album:after {
    content: "\e90e";
    .fs(22);
  }

  a.fancybox,
  a.fancybox-iframe {
    display: block;
  }

  .tag {
    text-transform: uppercase;
    background-color: @accentLight;
    color: @white;
    .fs(15);
    padding: 5px 12px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      right: 8px;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 10px 0;
      border-color: transparent @accentLight transparent transparent;
    }
  }

  .inner-content {
    position: absolute;
    left: 20px;
    bottom: 24px;
  }

  .count {
    background-color: @accentLight;
    background-color: fade(@accentLight, 80%);
    color: @white;
    display: block;
    padding: 0px 10px;
    .fs(14);
    font-weight: 700;

    i {
      margin-right: 4px;
    }

    &.zero {
      i {
        margin-right: 0;
      }
    }
  }
}

a .visual-wrapper {
  &:hover,
  &:focus {
    .inner-content {
      .button {
        &.more {
          &:after {
            right: 15px;
          }

          &:before {
            .transform(translateX(0));
          }
        }
      }
    }
  }
}

.visual--16x9 {
  padding-bottom: 56.25%;

  img {
    object-fit: cover;
    object-position: center;
    height: auto;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }
}

.visual--3x2 {
  padding-bottom: 66.6667%;
  position: relative;
  display: block;
  width: 100%;
  background: transparent;

  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: contain;
    object-position: center;
  }
}

.visual--cover {
  img {
    object-fit: cover;
  }
}

.visual--transparent {
  background: transparent;
}

.members-icon {
  height: 1.6em;
  width: 1em;
  display: inline-block;

  + span {
    margin-left: 0.5em;
  }
}

.exclusive-overlay__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: ~"calc(100% - 30px)";
  padding: 5% 8%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  pointer-events: none;

  span {
    color: white;
  }

  img {
    height: 3em;
    width: 2em;

    @media @tablet {
      height: 4em;
      width: 2.5em;
      margin-bottom: 15px;
    }
  }

  a {
    pointer-events: all;
  }
}

.exclusive-overlay__content--small {
  height: 100%;

  img {
    @media @tablet {
      height: 2.15em;
      width: 1.5em;
      margin-bottom: 15px;
    }
  }

  span {
    font-size: 13px;
    line-height: 1.25;
  }
}

.members-overlay {
  width: 100%;
  display: block;
  color: @white;
  font-size: 12px;
  line-height: 1.4;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    color: @white;

    .visual:before {
      opacity: 0.65;
    }
  }

  .members-overlay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    .transform(translateX(-50%) translateY(-50%));

    img {
      height: 3em;
      width: 2em;

      @media @tablet {
        height: 4em;
        width: 2.5em;
        margin-bottom: 15px;
      }
    }

    @media @tablet {
      width: auto;
    }
  }

  .visual {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: @black;
      opacity: 0.5;
      z-index: 1;
      .transition(0.2s);
    }
  }

  @media @tablet {
    font-size: 20px;
  }
}

.social-list.top {
  display: none;

  position: absolute;
  right: 0;
  top: 0;
  .fs(18);
  z-index: 2;

  li {
    &.label {
      background-color: @white;
      margin-bottom: 0;
      @media @tablet {
        a {
          width: 100px;
        }

        span {
          .fs(10);
        }
      }
      @media @wide {
        span {
          .fs(12);
        }
      }
      //@media @xwide {
      //	a {
      //		width: 50px;
      //	}
      //}
    }

    span {
      display: block;
      width: 100%;
      font-size: 10px;
      margin-bottom: 0;
      color: @accent;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translateX(-50%) translateY(-50%));
    }
  }

  @media @tablet {
    margin-top: -35px;
    margin-right: 0px;
    li {
      float: left;

      a {
        position: relative;
        .fs(14);
        height: 35px;
        width: 35px;
        line-height: 38px;
      }
    }
  }
  @media @desktop {
    display: block;
    margin-top: -35px;
    right: -30px;
  }
  @media @wide {
    margin-top: -50px;
    li {
      float: left;

      a {
        .fs(18);
        height: 50px;
        width: 50px;
        line-height: 53px;
      }
    }
  }
}

.date {
  .fs(12);
  font-weight: 400;
  color: @accentSecondary;
  padding-bottom: 0.2em;
  text-transform: uppercase;

  @media @desktop {
    .fs(14);
  }
}

// SPONSORS

.sponsors-title {
  text-align: center;
  display: block;
  margin: 50px 0 15px 0;
  text-transform: uppercase;
  color: @white;
}

.sponsors {
  padding: 30px 0 10px;
  background-color: @white;
  margin-left: -30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media @tablet {
    padding: 30px 0px 10px;
  }
  @media @desktop {
    padding: 40px 0px 20px;
  }
  @media @wide {
    padding: 60px 0px 60px;
  }
}

.sponsors-separator {
  position: relative;
  margin: 20px 0 40px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  * {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      188deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 33%,
      rgba(255, 255, 255, 0) 65%,
      rgba(255, 255, 255, 1) 100%
    );

    @media @tablet {
      background: linear-gradient(
        188deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 42%,
        rgba(255, 255, 255, 0) 58%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.sponsors-wrap {
  float: left;
  position: relative;
  width: 50%;
  padding: 0 10px;
  padding-left: 30px;

  &.main {
    display: inline-flex;
    justify-content: center;
    width: 75%;

    @media @tablet {
      width: 42.5%;
      margin-bottom: 30px;
    }

    @media @netbook {
      width: 25%;
    }

    a {
      max-width: initial;
      margin: 0 auto;
      display: block;
    }
  }

  &.small {
    margin-bottom: 30px;
    width: 50%;

    @media @tablet {
      width: 33.33%;
    }

    @media @netbook {
      width: 16.666%;
    }
  }

  &.x-small {
    margin-bottom: 30px;
    width: 50%;

    @media @tablet {
      width: 20%;
    }
    @media @netbook {
      width: 10%;
    }
  }

  a {
    height: 100%;
    max-width: 150px;
    display: block;
    margin: 0 auto;
  }
}

.sponsors--more {
  .main {
    width: 100%;

    @media @tablet {
      width: 33.3%;
    }
    @media @desktop {
      width: 20%;
    }
    @media @wide {
      width: 20%;
    }
  }

  .small {
    width: 50%;
    @media @tablet {
      width: 25%;
    }
    @media @desktop {
      width: 13%;
    }
    @media @wide {
      width: 13%;
    }
  }
}

.sponsor {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  .fade-link;
}

// NORMAL PAGES

.page-floating-content {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: @white;
    width: 30px;
    height: 100%;
    top: 0;
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }

  @media @desktop {
    margin-top: 60px;
  }

  @media @mobile {
    &:before,
    &:after {
      display: none;
    }
  }
}

.page-heading {
  background-color: @white;
  padding: 40px 0 35px;

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  .heading-game {
    margin-top: 15px;
    padding: 20px;
    color: @accent;
    text-transform: uppercase;

    strong {
      margin: 0 10px;
    }

    img {
      height: 50px;
      margin: 0 25px;
      @media @mobile {
        display: none;
      }
    }
  }
}

.heading-nav {
  display: inline-block;
  list-style: none;
  margin: 0;
  margin-top: 20px;

  li {
    float: left;
    margin: 0 10px;

    @media @mobile {
      display: none;
    }

    &.active {
      a {
        color: @accentLight;
      }
    }

    &.back {
      position: relative;
      padding-right: 20px;
      margin-right: 15px;

      @media @mobile {
        display: block;
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        .fs(18);
        line-height: 1.6em;

        .icon-arrow-left,
        .icon-arrow-right {
          top: -3px;
          position: relative;
        }
      }

      span {
        @media @mobile {
          display: inline-block;
        }

        .fs(20);
        margin-left: 10px;
        position: relative;
        top: -3px;
      }

      &:after {
        @media @tablet {
          content: "";
          position: absolute;
          display: block;
          width: 1px;
          height: 100%;
          right: 0;
          top: -2px;
          background-color: @accentLight;
          .transform(rotate(15deg));
        }
      }
    }
  }

  a {
    .fs(20);
    text-decoration: none;
    font-weight: 700;
    color: currentColor;

    &:hover,
    &:focus {
      color: @accentLight;
    }
  }

  &.without-list {
    .back {
      .mobile-show {
        display: inline-block;
      }

      &:after {
        display: none;
      }
    }
  }
}

.page-content {
  background-color: @white;
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 20px;
  z-index: 1;

  &.overflow {
    overflow-x: scroll;
  }
}

#error-screen-fix .privacy-policyformError {
  left: inherit !important;
  right: 0 !important;
}

.overview-grid-group {
  margin-bottom: 20px;
  @media @tablet {
    margin-bottom: 30px;
  }

  &.line {
    border-bottom: 1px solid @grayMiddle;
  }

  &.splitted {
    .overview-grid-contact-item {
      width: 100%;
    }
  }
}

.overview-grid-item {
  margin-bottom: 20px;
  width: 100%;

  @media @tablet {
    width: 50%;
    margin-bottom: 30px;
  }
  @media @tablet {
    width: 33.33%;
  }
  @media @wide {
    width: 25%;
  }

  h3 {
    .fs(24);

    @media @tablet {
      .fs(36);
    }
  }

  &.overview-grid-item--rte {
    width: 100%;

    @media @tablet {
      width: 50%;
    }
    @media @wide {
      width: 50%;
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      .overlay span {
        background-color: fade(@accentLight, 100%);
      }

      .image {
        opacity: 0.5;
        .transform(scale(1.05));
      }

      .content {
        &:after {
          -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
            translate3d(0, 200%, 0);
          transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
            translate3d(0, 200%, 0);
          @media @mobile {
            -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
              translate3d(0, 250%, 0);
            transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
              translate3d(0, 250%, 0);
          }
        }

        &:before {
          opacity: 0.75;
          .transition-delay(0.1s);
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 25px 20px;
    height: 200px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      -webkit-transition: -webkit-transform 0.6s;
      transition: transform 0.6s;
      -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, -150%, 0);
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, -150%, 0);
      @media @mobile {
        -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -200%, 0);
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -200%, 0);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: @accent;
      opacity: 0;
      .transition;
      .transition-delay(0s);
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: @grayLight;
      background-size: cover;
      background-position: center center;
      text-align: center;
      padding: 25px 20px;
      .transform(scale(1));
      .transition(0.3s);

      &.valign-top {
        background-position: center top;
      }

      &.valign-bottom {
        background-position: center bottom;
      }
    }

    .overlay {
      position: relative;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      span {
        position: relative;
        z-index: 2;
        display: block;
        padding: 12px 20px;
        background-color: @accentLight;
        color: @white;
        text-align: center;
        width: auto;
        .transition(0.3s);

        > * {
          margin-bottom: 0;
          color: @white;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}

.overview-grid-team-item {
  margin-bottom: 50px;
  width: 50%;
  @media @tablet {
    width: 33.33%;
  }
  @media @desktop {
    width: 33.33%;
  }
  @media @wide {
    width: 20%;
  }

  a {
    &:hover,
    &:focus {
      .number {
        background-color: @accentLight;
      }

      img {
        .transform(scale(1.05));
      }
    }
  }

  .image-wrap {
    display: block;
    overflow: hidden;
  }

  .image {
    position: relative;
    margin-bottom: 10px;

    img {
      width: 100%;
      .transform(scale(1));
      .transition;
    }
  }

  .number {
    text-align: center;
    background-color: @accent;
    color: @white;
    padding: 10px;
    font-weight: 700;
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    .transform(translateY(-33.33%));
    .fs(24);
    width: 50px;
    height: 50px;
    line-height: 33px;
    .transition;
    @media @tablet {
      .fs(28);
      width: 70px;
      height: 70px;
      line-height: 53px;
    }
  }

  .title {
    padding-right: 90px;
    font-weight: 700;

    span {
      display: block;
      line-height: 1.2em;
    }
  }
}

.overview-grid-contact-item {
  width: 100%;
  @media @tablet {
    width: 50%;
  }
  @media @desktop {
    width: 50%;
  }
  @media @wide {
    width: 33.33%;
  }

  margin-bottom: 30px;
  @media @tablet {
    margin-bottom: 50px;
  }

  .info {
    margin-bottom: 15px;
    .fs(14);
  }

  p {
    margin-bottom: 0;
  }

  a {
    font-weight: 400;
    text-decoration: none;

    &:hover,
    &:focus {
      color: @accentHover;
    }
  }

  .h4 {
    text-transform: initial;
    font-weight: 400;
  }

  ul {
    list-style: none;
    margin: 0;
    margin-top: 5px;

    li {
      a {
        #font > .default;
        display: inline-block;
        .fs(14);
      }
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.overview-grid-media-item {
  position: relative;
  width: 100%;

  @media @tablet {
    width: 50%;
  }

  @media @desktop {
    width: 25%;
  }

  @media @wide {
    width: 20%;
  }

  a.fancybox {
    display: flex;
  }

  .visual-wrapper {
    margin-bottom: 6px;

    .visual {
      width: 100%;
      padding-bottom: 56.25%;
      background-color: @grayLight;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.video {
    .visual-wrapper {
      .visual {
        padding-bottom: 56.25%;
        background-size: cover;
      }
    }
  }

  margin-bottom: 30px;
  @media @tablet {
    margin-bottom: 30px;
  }
}

.overview-grid-wigwam-item {
  width: 100%;
  @media @tablet {
    width: 50%;
  }
  @media @desktop {
    width: 25%;
  }
  @media @wide {
    width: 20%;
  }

  .wigwam-cover {
    margin-bottom: 6px;
  }

  margin-bottom: 30px;
  @media @tablet {
    margin-bottom: 30px;
  }
}

// NEWS

.news-grid {
  .post-item {
    margin-bottom: 30px;
  }

  .post-item-wrapper {
    .post-item {
      margin-bottom: 0;
      position: relative;
      height: 100%;
      padding-bottom: 90px;

      a.button {
        position: absolute;
        bottom: 60px;
      }
    }

    &:nth-child(3n + 1) {
      clear: both;
    }
  }

  &.big {
    .one-third {
      width: 100%;
      @media @tablet {
        width: 50%;
      }
      @media @desktop {
        width: 33.33%;
      }
    }

    .post-item {
      &.with-visual {
        .dotdotdot {
          @media @tablet {
            height: 150px;
          }
          @media @desktop {
            height: 150px;
          }
          @media @wide {
            height: 50px;
          }
        }
      }

      .dotdotdot {
        height: 80px;
        @media @tablet {
          height: 305px;
        }
        @media @desktop {
          height: 285px;
        }
      }
    }
  }
}

.post-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover,
  &:focus,
  &:active {
    img {
      transform: scale(1.1);
    }
  }

  .button {
    &.link {
      color: @fontColor;
    }
  }

  .visual {
    overflow: hidden;
    margin-bottom: 30px;
  }

  img {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .visual-wrapper {
    margin-bottom: 0;
    width: 100%;

    &.no-visual {
      margin-bottom: 50px;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .double-line {
    height: 40px;
  }

  .fade-link {
    display: block;
  }

  .h4,
  a {
    color: @fontColor;
  }

  &.small {
    .visual-wrapper {
      margin-bottom: 10px;
    }
  }

  &.with-visual {
    .dotdotdot {
      @media @tablet {
        height: 150px;
      }
      @media @desktop {
        height: 150px;
      }
      @media @wide {
        height: 50px;
      }
    }

    + .rte {
      margin-top: 15px;
    }
  }

  .dotdotdot {
    height: 80px;
    @media @tablet {
      height: 285px;
    }
  }
}

.post-item__body {
  width: 50%;
  min-width: 50%;
  margin-left: 10px;

  @media @tablet {
    width: 100%;
    margin-left: 0;
  }
}

.post-item--small {
  flex-direction: row;

  .dotdotdot {
    display: none;
  }

  .visual-wrapper {
    span {
      padding: 3px 5px;
      .fs(13);

      @media @tablet {
        padding: 5px 12px;
        .fs(15);
      }
    }

    .tag {
      display: none;

      @media @tablet {
        display: block;
      }
    }
  }

  .visual {
    margin-bottom: 20px;
  }

  &.post-item--exclusive {
    .exclusive-overlay__content {
      height: ~"calc(100% - 10px)";
      padding: 10% 8% 5% 8%;

      img {
        height: 3em;
        margin-bottom: 0.3em;
      }

      span {
        line-height: 1.1em;
        font-weight: 500;
      }

      @media @mobile {
        padding: 5% 8% 5% 8%;
        img {
          height: 2em;
        }
      }
    }
  }

  .post-item__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tag {
      text-transform: uppercase;
      background-color: @accentLight;
      color: @white;
      .fs(12);
      padding: 2px 8px;
      font-weight: 700;
      margin-bottom: 10px;
      @media @tablet {
        display: none;
      }
    }

    .date {
      display: none;

      @media @tablet {
        display: block;
      }
    }
  }

  a {
    color: @white;

    &:hover,
    &:focus {
      color: @accentLight;
    }
  }

  @media @tablet {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.dotdotdot {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.related-news {
  @media @tablet {
    margin-top: 30px;
  }
  @media @desktop {
    margin-top: 50px;
  }
  @media @wide {
    margin-top: 100px;
  }
}

.load-more {
  margin-top: 30px;
  @media @tablet {
    margin-top: 60px;
  }
}

.share-page.share-page {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    color: @gray;
    margin-left: -10px;
  }

  .count {
    display: block;
    background-color: @grayLight;
    font-size: 10px;
  }

  a {
    width: 30px;
    height: 30px;
    font-size: 2rem;
  }

  @media @netbook {
    position: fixed;
    right: 0;
    top: 50%;
    .transform(translateY(-50%));

    label {
      width: 60px;
    }

    .count {
      font-size: 12px;
    }
  }

  @media @xwide {
    right: 10px;
  }

  .whatsapp {
    display: inline-flex;

    @media @desktop {
      display: none;
    }
  }
}

// PLAYER

.player {
  position: relative;
  display: block;
  max-width: 500px;
  margin: 0 auto;

  .number {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: @accent;
    color: @white;
    text-align: center;
    font-weight: 700;
    width: 100px;
    height: 100px;
    line-height: 100px;
    .fs(60);

    @media @tablet {
      .transform(translateX(-33.33%));
      .fs(85);
      width: 150px;
      height: 150px;
      line-height: 150px;
    }
  }
}

// FILTERS

.filter-wrapper {
  margin-bottom: 30px;

  @media @tablet {
    margin-bottom: 40px;
  }

  > .row {
    .one-third {
      width: 100%;
      @media @tablet {
        width: 50%;
      }
      @media @desktop {
        width: 33.33%;
      }
    }

    .two-third {
      width: 100%;
      @media @tablet {
        width: 50%;
      }
      @media @desktop {
        width: 66.66%;
      }
    }
  }

  .filter-group {
    margin-bottom: 30px;

    &.mb20 {
      margin-bottom: 20px;
    }

    &.mb0 {
      margin-bottom: 0px;
    }
  }

  .filter-tags {
    ul {
      margin: 0 0 -10px -10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      .clearfix;

      li {
        @media @tablet {
          float: left;
        }
        margin-left: 10px;
        margin-bottom: 10px;

        &.active {
          a {
            background-color: @accentLight;
            color: @white;
          }
        }
      }

      a {
        background-color: @grayLight;
        color: @gray;
        display: block;
        .fs(16);
        padding: 8px 10px;
        text-decoration: none;
        font-weight: 700;
        border-radius: 5px;
        letter-spacing: 0.02em;

        &:hover,
        &:focus {
          background-color: @accentLight;
          color: @white;
        }
      }
    }

    &.filter-tags--accent {
      a {
        color: @white;
        background-color: @accentLight;
      }
    }
  }

  &.filter-wrapper--no-border {
    border: none;
  }

  &.filter-wrapper--no-mb {
    margin-bottom: 0px;

    @media @tablet {
      margin-bottom: 0px;
    }
  }
}

.bg-accentLighter {
  .filter-tags ul a {
    background-color: @white;
  }
}

// TICKETS

.tribune {
  margin-bottom: 60px;

  h3 {
    margin-bottom: 0;
    padding: 10px;
    color: @white;
    position: relative;

    .icon {
      position: absolute;
      right: 10px;
      top: 20%;
      display: block;
      height: 60%;

      img {
        height: 100%;
      }
    }

    @media @mobile {
      .fs(12);
    }
  }

  .category-block {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0 10px 0 0;
    background: @gray;
    vertical-align: middle;
  }

  table {
    .fs(10);
    @media @tablet {
      .fs(14);
    }
  }

  thead {
    background-color: @grayLight;

    td,
    th {
      border-bottom: 0px solid @gray;
      text-align: center;
    }
  }

  tbody {
    td {
      border-bottom: 1px solid @grayMiddle;
      text-align: center;
      min-width: 75px;

      img {
        display: inline-block;
        height: 30px;
        margin-right: 10px;
      }

      &.name {
        text-align: left;
        width: 100%;
      }

      &.seats {
        white-space: nowrap;

        @media @tablet {
          white-space: normal;
          min-width: 200px;
        }
        @media @desktop {
          min-width: 200px;
        }
      }

      &.discount {
        min-width: 20px;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: 1px solid @grayMiddle;
        }
      }
    }
  }
}

.tribune__table {
  width: 100%;
  overflow-x: scroll;

  @media @tablet {
    overflow-x: hidden;
  }
}

//SEARCH A-Z PAGE

.alphabet-block {
  position: relative;

  .letter {
    color: @accentLight;
    position: relative;
    top: 0;
    left: 0;
    .fs(40);
    margin-bottom: 0;
    display: inline-block;
  }

  ul {
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

// POPUP

.popup {
  padding: 30px;
}

.popup--shirt {
  padding: 0;
}

.popup-shirt__right {
  position: absolute;
  right: 3.2%;
  bottom: 3%;
  width: 9.375%;
  height: 6.66%;
  z-index: 9;
}

.popup-shirt__left {
  position: absolute;
  left: 2%;
  bottom: 3%;
  width: 16%;
  height: 6.66%;
  z-index: 9;
}

// COMMUNITY

.community {
  .hero {
    height: 250px;
    background-size: cover;
    background-position: center center;
    border-bottom: 40px solid @accent;
    @media @tablet {
      height: 350px;
    }
    @media @desktop {
      height: 500px;
    }
  }

  .community-logo {
    width: 120px;
    display: block;
    margin: 0 auto;
    margin-top: -70px;
    margin-bottom: 30px;
    @media @tablet {
      width: 180px;
      margin-top: -90px;
    }
    @media @desktop {
      width: 250px;
      margin-top: -120px;
    }
  }

  .related-news {
    margin-top: 50px;
  }
}

// EXECUTIVE

.main.executive {
  background-color: @black;
  background-image: url("../images/executive/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Lora";

  h1 {
    display: inline-block;
    font-family: "Great Vibes";
    .fs(45);
    @media @tablet {
      .fs(65);
    }
    @media @desktop {
      .fs(95);
    }
    border-bottom: 1px solid @gold;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: @gold;
    text-transform: initial;
    font-weight: 400;
  }

  a {
    color: @white;
  }

  hr {
    border-color: @gold;
    max-width: 50%;
    margin: 60px auto;
  }

  label {
    color: @white;
    font-weight: 400;
    font-family: "Lora";
  }

  input,
  select {
    border-color: #33414e;
    background-color: #010a14;
    color: @white;

    &:disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  //input[type="email"], input[type="text"], input[type="password"] {
  //	-webkit-box-shadow:0 0 0 50px #010a14 inset; /* Change the color to your own background color */
  //	-webkit-text-fill-color: #fff;
  //}

  .intro {
    padding: 20px 0 40px;
    text-align: center;

    .button {
      float: none;
      display: inline-block;
    }
  }

  input[type="radio"] + label span {
    border-color: @gold;
    background-color: #010a14;
    margin: -2px 8px 0 0;
  }

  input[type="radio"]:checked + label span {
    border-color: @gold;
  }

  input[type="radio"]:checked + label span:after {
    background-color: @white;
  }

  input[type="checkbox"]:not(:checked) + label,
  input[type="checkbox"]:checked + label {
    padding-left: 27px;
  }

  input[type="checkbox"]:not(:checked) + label:before,
  input[type="checkbox"]:checked + label:before {
    border-color: @gold;
    background-color: #010a14;
    width: 18px;
    height: 18px;
    top: 2px;
  }

  input[type="checkbox"]:not(:checked) + label:after,
  input[type="checkbox"]:checked + label:after {
    color: @white;
  }

  .button {
    text-transform: uppercase;
    background-color: @gold;
    letter-spacing: 0.05em;

    &:hover,
    &:focus {
      background-color: darken(@gold, 10%);
    }
  }

  .page-heading {
    background-color: transparent;
    margin-top: 50px;

    h1 {
      color: @gold;
    }
  }

  .page-content {
    background-color: transparent;
    color: @white;
  }

  .box {
    border: 4px solid @gold;
    padding: 20px;
    background: transparent;

    &.success {
      color: @white;
      border-color: @white;
    }
  }

  .alert {
    margin: 15px 0;
  }

  .executive-contact {
    color: @gold;
    margin-top: 4px;
    display: inline-block;
    .fs(16);
    @media @tablet {
      .fs(21);
    }

    a {
      color: @gold;
      text-decoration: none;

      &:hover,
      &:focus {
        color: darken(@gold, 10%);
      }
    }
  }

  .form-element {
    .wrap {
      position: relative;

      input {
        padding-right: 45px;
      }
    }
  }

  .password-reset {
    display: inline-block;
    margin-top: 15px;
  }

  .remove-extra-person {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 32px;
    transition: 0.3s;
    cursor: pointer;
    height: 45px;
    width: 45px;
    display: block;
    text-align: center;
    line-height: 44px;

    span {
      display: inline-block;
      .transform(rotate(45deg));
    }

    &:hover,
    &:focus {
      color: @gold;
    }
  }

  .extra-persons {
    padding-left: 27px;
  }

  .add-extra-person {
    display: inline-block;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    visibility: visible;
    .transition(0.3s);
    border-bottom: 1px solid transparent;
    padding: 2px 0px;

    span {
      color: @gold;
      font-weight: 600;
      .fs(24);
      position: relative;
      top: 3px;
      .transition(0.3s);
      display: inline-block;
      .backface-visibility(hidden);
    }

    &:hover,
    &:focus {
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border-bottom: 1px solid @white;
    }
  }
}

/* =============================================================================
   PAGINATION
   ========================================================================== */

.pagination {
  display: inline-block;

  li {
    display: block;
    float: left;
    margin: 0 5px;
    min-width: 25px;
    background-color: transparent;
    #font > .heading;
    .fs(18);

    @media @tablet {
      .fs(22);
    }

    @media @desktop {
      .fs(26);
    }

    .transition;

    &.active {
      background-color: @accentLighter;
    }

    &.disabled {
      opacity: 0.25;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }

    span {
      padding: 0 0.1em;
      display: block;
    }

    a {
      padding: 0 0.1em;
      display: block;
    }
  }
}

// SOCIOS

.sticker {
  background-color: @accentHover;
  height: 80px;
  margin-bottom: 30px;
  position: relative;
  @media @tablet {
    height: 100px;
  }
  @media @desktop {
    height: 120px;
  }
  @media @wide {
    height: 155px;
  }

  .image {
    padding: 20px;
    float: left;
    height: 100%;

    img {
      height: 100%;
      width: auto;
      display: block;
    }
  }

  .text {
    display: block;
    color: white;
    text-align: center;
    font-size: 20px;
    height: 100%;
    line-height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media @tablet {
      font-size: 30px;
      line-height: 100px;
    }
    @media @desktop {
      font-size: 40px;
      line-height: 120px;
    }
    @media @wide {
      font-size: 50px;
      line-height: 155px;
    }
  }
}

// Quiz
.landing-quiz {
  background-color: @accentLight;
  background-image: url("../images/layout/pattern.png");
  display: flex;
  align-items: center;
  overflow: hidden;

  @media @tablet {
    min-height: ~"calc(100vh - 101px)";
  }

  @media @wide {
    min-height: ~"calc(100vh - 196px)";
  }
}

.logoBig {
  display: none;
  background-image: url("../images/quiz/indiaanBig.svg");
  background-repeat: no-repeat;
  width: 1000px;
  height: 1000px;
  position: absolute;
  left: -700px;
  @media @tablet {
    display: block;
  }
}

.landing-quiz__content {
  max-width: 960px;
  margin: auto;
  padding: 20px;
  z-index: 5;

  img {
    display: block;
    margin: auto;
    -webkit-filter: drop-shadow(12px 12px 25px rgba(255, 255, 255, 0.5));
    height: 100px;
  }

  p {
    text-align: center;
    margin-top: 100px;
  }

  @media @tablet {
    img {
      height: 150px;
    }

    p {
      margin-top: 100px;
    }
  }
  @media @desktop {
  }
  @media @wide {
    img {
      height: 250px;
    }

    p {
      margin-top: 200px;
    }
  }
}

.h1.title {
  color: white;
  text-transform: none;
}

.row.row--quiz {
  margin-bottom: 50px;
}

.one-half.one-half--quiz {
  .question-element {
    position: relative;
    top: -15px;
    height: 52px;
    width: 139px;
    padding-top: 12px;
    padding-left: 12px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    background-image: url(../images/quiz/QuestionBox.svg);
  }

  .form-element {
    padding: 20px;
    padding-top: 0;
  }

  @media @tablet {
    .question-element {
      left: -150px;
    }
  }
  @media @desktop {
  }
  @media @wide {
    .question-element {
      top: -20px;
      left: -60px;
    }

    .form-element {
      padding-top: 20px;
      margin-top: 20px;
    }
  }
}

.middle {
  margin: auto;
}

// Quiz result
.page-content--nohead {
  margin-top: 100px;
}

.score {
  z-index: 1;
  text-align: right;
  padding-right: 35%;

  p {
    font-size: 200px;
    color: @accent;
    margin-bottom: 0px;
  }
}

.total-questions {
  z-index: -2;
  position: absolute;
  bottom: 0;
  right: 50%;

  p {
    text-align: right;
    font-size: 200px;
    color: @accentLight;
    margin-bottom: 0px;
    padding-right: 20%;
  }
}

.row--result {
  height: 300px;
}

.quiz-result-text {
  line-height: 2em;
}

.one-half--small {
  @media @tablet {
    display: none;
  }

  h1 {
    margin-bottom: 50px;
  }
}

.one-half--big {
  display: none;
  @media @tablet {
    display: block;
  }
}

.fb-share {
  background-color: @accent;
  margin-top: 50px;
  display: table;

  > div {
    float: left;
  }

  .fb-share__logo {
    border-right: 1px solid white;
    padding: 10px;

    img {
      height: 21px;
      margin: 0 8px;
    }
  }

  .fb-share__text {
    color: white;
    text-transform: uppercase;

    p {
      margin: 10px 20px;
      font-weight: bold;
      position: relative;
      top: 1px;
    }
  }
}

/* =============================================================================
   TRIGGER
   ========================================================================== */

.toggle-content {
  display: none;
}

.toggle-trigger {
  cursor: pointer;
}

.toggle-wrapper {
  &.toggle-mobile {
    @media @tablet {
      .toggle-content {
        display: block;
      }

      .toggle-trigger {
        display: none;
      }
    }
  }
}

/* =============================================================================
   RTE
   ========================================================================== */

.virtual-tour-business {
  p:last-of-type {
    display: block;
    height: 0;
    padding-bottom: 140%;
    overflow: hidden;
    position: relative;
    margin: 0 0 30px;

    @media @tablet {
      padding-bottom: 74%;
    }
  }

  iframe {
    height: 710px;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: ~"calc(100vh - 150px)";
  }
}

.rte {
  @media @tablet {
    .fs(15);
  }

  a {
    color: @accent;

    &:hover,
    &:focus {
      color: @accentLight;
    }

    &.button {
      float: none;
      display: inline-block;
      color: @white;
      margin-bottom: 0.5em;

      @media @mobile {
        width: 100%;
        text-align: center;
      }
    }
  }

  table {
    max-width: 100%;
    @media @mobile {
      width: auto !important;
    }
  }

  strong {
    font-weight: 700;
  }

  h2 {
    color: @accent;
    text-transform: initial;
  }

  h3 {
    text-transform: uppercase;
    color: @grayDarker;
    margin-bottom: 0.4em;
  }

  h4 {
    color: @grayDarker;
    margin-bottom: 0.4em;
    text-transform: initial;
  }

  ul {
    list-style: square;
    margin-bottom: 30px;

    li {
      position: relative;

      img {
        margin-top: 30px;
      }

      p,
      h1,
      h2,
      h3 {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        display: none;
        position: absolute;
        margin-top: 0.65em;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        background-color: @accentLight;
      }

      li {
        &:before {
          background-color: @accent;
        }

        li {
          &:before {
            display: none;
          }
        }
      }
    }

    //todo: this class is from the old cms. needs to be replaced with a new one to make a grid in rte
    &.album_list {
      .clearfix;
      margin: 0;
      list-style: none;
      margin-left: -30px;
      .equal-h;

      li {
        float: left;
        margin-bottom: 30px;
        padding-left: 30px;
        width: 50%;
        @media @desktop {
          width: 25%;
        }

        img {
          width: 100% !important;
        }
      }
    }
  }

  img {
    display: block;
    width: auto;
    height: auto !important;
    margin-bottom: 30px;

    &.align-left,
    &.align-right {
      @media @tablet {
        max-width: 50%;
      }
    }

    &.image-left {
      @media @mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      @media @tablet {
        float: left;
        width: auto;
        margin-right: 30px;
      }
    }

    &.image-right {
      @media @mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      @media @tablet {
        float: right;
        width: auto;
        margin-left: 30px;
      }
    }

    &.full-img {
      width: 100% !important;
    }

    &.center-img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  blockquote {
    color: @accentLight;
    .fs(18);
    border-left: 5px solid @accentLight;
    padding-left: 20px;
    margin: 30px 0px;
    @media @tablet {
      margin: 60px 0px;
      .fs(21);
    }

    p {
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  table {
    margin-bottom: 20px;

    tr {
      &:last-child {
        td {
          border-bottom: 0px solid @gray;
        }
      }
    }

    td {
      border-bottom: 1px solid @gray;
      padding: 8px 6px;

      &:last-child {
        text-align: right;
      }
    }
  }

  input {
    display: block;
  }

  input[type="radio"] {
    display: inline-block;
    margin-right: 10px;
  }

  iframe {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

  .highlight-dark {
    display: block;
    background-color: @accent;
    text-align: center;
    padding: 30px;
    color: @white;
    margin: 30px 0px;
    @media @tablet {
      margin: 60px 0px;
      padding: 60px;
    }

    h3 {
      color: @white;
      margin-bottom: 2em;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .highlight-light {
    display: block;
    background-color: @accentLighter;
    padding: 30px;
    margin: 30px 0px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .datepicker {
    .clearfix;

    select {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      select {
        width: 26%;
        float: left;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.intro-info {
  background-color: @accentLighter;
  padding: 30px;
  margin-bottom: 30px;
  @media @tablet {
    margin-bottom: 60px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.powered-by {
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  #font > .default;
  text-transform: none;
  letter-spacing: 0;

  p {
    text-align: center;
    color: white;
    margin: 0;
  }

  img {
    margin-left: 5px;
    width: 90px;
    height: 1.5em;
  }

  span {
    margin-left: 5px;
    color: white;
    font-weight: 600;
    line-height: 1.5em;
  }
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm 0.5cm 1cm;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .one-half {
    width: 50%;
  }

  .two-third {
    width: 66.66%;
  }

  .one-third {
    width: 33.33%;
  }

  .three-fourth {
    width: 75%;
  }

  .one-fourth {
    width: 25%;
  }
}

.clickable-row {
  cursor: pointer;
}

.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

.member-modal {
  .modaal-container {
    max-width: 800px;
  }

  .modaal-content-container {
    padding: 60px;
  }
}

.label-note {
  background-color: @accentLight;
  padding: 0.4em 1em;
  color: @white;
  font-weight: 700;
  .fs(22);
  text-transform: uppercase;
  text-align: center;
  z-index: 2;

  @media @desktop {
    position: absolute;
    right: 100%;
    top: 0;
    .transform(rotate(-90deg) translateY(-100%));
    .transform-origin(100% 0%);
  }
}

.label-note--right {
  right: 0;
  transform: rotate(0);

  &:before {
    content: none;
  }
}
