.preview-item {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  text-decoration: none;

  * {
    text-decoration: none;
  }

  &.preview-item--relative {
    height: auto;

    .preview-item__visual,
    .preview-item__body {
      position: relative;
    }

    .preview-item__visual {
      position: relative;

      .visual {
        position: relative;
      }
    }

    .preview-item__body {
      left: 0;
      bottom: 0;
      top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      min-height: 0;
    }

    .preview-item__body--light {
      background: transparent;
      color: white;

      * {
        color: white;
      }

      .button {
        color: white;
      }
    }
  }

  &.preview-item--teams,
  &.preview-item--spotlight-page {
    aspect-ratio: 8 / 5;
  }
}

.preview-item--link {
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    .button {
      color: white;

      &:after {
        right: 15px;
      }
    }
  }
}

.preview-item--shadow {
  box-shadow: 0px 20px 60px rgba(11, 45, 97, 0.2);
}

.preview-item__body {
  position: relative;
  height: auto;
  width: 100%;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  min-height: 180px;

  &.preview-item__body--light {
    padding: 15px;
    width: auto;
    height: auto;
    bottom: 25px;
    left: 20px;
    display: inline-flex;
    min-height: 0;
    color: @fontColor;
    margin-left: 30px;

    .button {
      color: @fontColor;
    }

    @media @tablet {
      padding: 30px;
      margin-left: 0;
    }
  }

  &.preview-item__body--end {
    justify-content: flex-end;
  }
}

.preview-item__title {
  color: @fontColor;

  span,
  .h1,
  .h2 {
    display: block;
    max-width: 10ch;
    margin-bottom: 0.5em;
    color: @fontColor !important;
  }
}

.preview-item__visual {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;

  .visual {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;

    img {
      min-height: 100%;
      min-width: 100%;
    }
  }

  img {
    min-height: 100%;
    min-width: 100%;
  }
}

.preview-item--wide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -15px;
  margin-bottom: -15px;

  .preview-item__visual {
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;

    .visual {
      position: relative;
    }
  }

  .preview-item__body {
    padding-left: 15px;
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    margin-bottom: 15px;
    padding-right: 0;

    date {
      margin-bottom: 0.4em;
      display: block;
    }

    p {
      min-height: 0;
      margin-bottom: 0;
    }

    @media @mobile {
      width: 100%;
    }

    @media @tablet {
      width: 100%;
    }

    @media @desktop {
      width: auto;

      p {
        min-height: 3em;
      }
    }
  }
}

.visual-overlay {
  position: absolute;
  top: 25px;
  left: 20px;
  right: 20px;
  width: ~"calc(100% - 40px)";
  height: auto;
  bottom: auto;
  min-height: 0 !important;
  min-width: 0 !important;
}
