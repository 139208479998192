.dropdown {
  position: relative;
  cursor: pointer;
  isolation: isolate;

  &:hover,
  &:focus-within {
    .dropdown__list {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }

    .dropdown__trigger:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: -33%;
      right: -33%;
      height: 200%;
    }
  }
}

.dropdown__list {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 15rem;
  padding: 2rem 0;
  background: @white;
  background: linear-gradient(#f3f3f3 0%, #fff 100%);
  box-shadow: 0px 20px 40px rgba(0, 65, 133, 0.16);
  transition: opacity 0.3s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transform: translateY(15px);
  list-style: none;

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0%;
    left: 2em;
    right: auto;
    border-left: inherit;
    border-bottom: inherit;
    width: 0.8em;
    height: 0.8em;
    border-radius: 0 0 0 0.2em;
    transform: rotate(45deg) translateY(-50%);
    background: linear-gradient(-45deg, #f3f3f3 100%, #fff 200%);
  }

  &:after {
    content: "";
    width: 40px;
    height: 40px;
    top: 0%;
    position: absolute;
    right: 2em;
    background: transparent;
    transform: translateY(-80%);
  }

  li {
    margin-bottom: 0;
    + li {
      margin-top: 0.2em;
    }
  }
}

.dropdown__list--right {
  right: 0;
  left: auto;

  &:before {
    right: 2em;
    left: auto;
  }
}

.dropdown__list--left {
  left: 0;
  right: auto;

  &:before {
    left: 2em;
    right: auto;
  }
}

.dropdown__link {
  text-decoration: none;
  color: @fontColor !important;
  line-height: 1.5em !important;
  display: block;
  padding: 0 2rem !important;
  text-transform: capitalize;
  font-weight: 600;
}

.dropdown__item--header {
  + .dropdown__item:not(.dropdown__item--header) {
    margin-top: 1.4em;
  }
}

.dropdown__trigger {
  position: relative;

  i::before {
    display: inline-block;
    .transitionprop(transform, 0.2s);
  }

  &:hover,
  &:focus {
    i::before {
      transform: rotate(-180deg);
    }
  }
}

.dropdown--main-nav {
  .dropdown__trigger {
    white-space: nowrap;
    #font > .heading;
    text-transform: uppercase;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.2em;
      font-size: 0.8em;
    }
  }
}

.dropdown--more-menu {
  margin-left: 3vw;
  background-color: @accentSecondary;
  color: @white;
  display: none;

  .dropdown__trigger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 100%;
    background-color: @accentSecondary;
    border-color: @accentSecondary;
    padding: 0.6em 1.2em;

    i {
      margin-bottom: 20px;
    }

    i + span {
      margin-left: 0;
      color: @white;
      .fs(14);
      line-height: 1.4em;
    }
  }

  @media @desktop {
    display: block;
  }
}

.dropdown--main-nav,
.dropdown--more-menu {
  position: static;

  .dropdown__list {
    left: 0;
    right: 0;
    background: @accent;
    color: @white;
    transform: translateY(5px);

    li {
      display: flex;
      padding-left: 1.5vw;
    }

    a {
      text-transform: uppercase;
      color: @white;
      text-decoration: none;
      .fs(30);

      @media @tablet {
        font-weight: 700;
        .fs(30);
      }

      @media @desktop {
        .fs(30);
      }

      @media @xxwide {
        .fs(35);
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transform: none;
      background: @accent;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }
}
