.multi-info-block {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 0;

  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: -10px;
    justify-content: center;

    li {
      padding-left: 10px;
    }
  }

  p {
    color: @fontColor !important;
  }
  @media @wide {
    margin-bottom: 30px;
  }

  @media @desktop {
    flex-direction: column;
  }

  .tabs {
    list-style: none;
    margin: 0;
    width: auto;
    display: flex;
    flex-direction: column;

    @media @desktop {
      flex-direction: row;

      li {
        a {
          text-align: right;
        }
      }
    }

    li {
      position: relative;
      text-align: center;
      flex: 1;

      &:last-child {
        border-right: 0px solid transparent;
      }

      a {
        display: block;
        background-color: @accentLight;
        padding: 25px 25px;
        text-decoration: none;
        color: @white;
        font-weight: 700;
        cursor: pointer;
        text-align: left;

        @media @mobile {
          .fs(12);
        }
      }
      &.active {
        background-color: #064891;
      }
      &:hover,
      &:focus,
      &.active {
        a {
          background-color: @accentHover;
        }
      }
    }
  }
  .info-block-wrapper {
    position: relative;
    background-color: @white;
    color: @accent;
    flex: 1;
    margin-left: 0;
    margin-bottom: 30px;

    @media @desktop {
      min-height: auto;
      padding-left: 0;
    }
    .arrows {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 74px;
      @media @tablet {
        height: 76px;
      }
      @media @desktop {
        height: 79px;
      }
    }
  }
  .info-block {
    &.active {
      display: block;
    }
    .title {
      position: relative;
      height: 50px;
      color: @fontColor;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin-bottom: 0;
        max-width: 35ch;
        min-width: 30ch;
        display: block;
        margin: 0 auto;
        color: @fontColor;

        @media @mobile {
          .fs(20);
          max-width: 70%;
        }
        @media @tablet {
          .fs(22);
        }
        @media @desktop {
          .fs(22);
        }
      }
    }
    .title--center {
      text-align: center;
      justify-content: center;
    }
    .content {
      position: relative;
      padding: 2em;
      display: flex;
      flex-direction: column;

      @media @tablet {
        padding: 2em;
      }

      @media @desktop {
        min-height: auto;
        padding: 15px 20px;
      }

      table {
        margin-bottom: 30px;
      }

      .wrap {
        position: relative;
      }

      .button {
        &.link {
          color: @fontColor;
        }
      }

      &.with-title {
        @media @desktop {
          height: 190px;
          min-height: auto;
          min-height: initial;
        }
        @media @wide {
          height: 190px;
        }
        @media @xwide {
          height: 220px;
        }
      }
    }
    .clubs {
      width: 100%;
    }
    .club-wrap {
      padding: 10px 10px;
    }
    .club {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      display: block;
      margin: 0 auto;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 60px;
      height: 60px;
      @media @tablet {
        width: 75px;
        height: 75px;
      }
      @media @desktop {
        width: 100px;
        height: 100px;
      }
    }
    .club-title {
      margin-bottom: 0;
      .fs(20);
      height: 32px;
      @media @tablet {
        .fs(22);
      }
      @media @desktop {
        .fs(22);
      }
    }
    .scoreboard {
      font-weight: 700;
      color: @accent;
      .fs(26);
      height: 80px;
      @media @tablet {
        .fs(22);
        height: 95px;
      }
      @media @desktop {
        .fs(30);
        height: 120px;
      }
      #match-not-future {
        display: none;
      }
      #match-future {
        display: block;
      }
      &.played-or-live {
        #match-not-future {
          display: block;
        }
        #match-future {
          display: none;
        }
      }
    }
    .timeboard {
      position: absolute;
      top: 0;
      left: 50%;
      .transform(translateX(-50%));
      background-color: @accentLight;
      color: @white;
      padding: 6px 18px;
      line-height: 1em;
      .fs(14);
      font-weight: 700;
    }
    .game-buttons-wrap {
      min-height: 110px;
      padding: 15px;

      p {
        color: @fontColor;
      }
      &.multiple-mobile {
        @media @mobile {
          min-height: 187px;
        }
      }
    }
    .splitter {
      display: block;
      width: 1px;
      height: 100%;
      position: relative;
      background-color: lighten(@gray, 15%);
      left: 50%;
      .transform(translateX(-0.5px));
    }
    .border-right {
      border-right: 1px solid lighten(@gray, 15%);
    }

    .cycle-arrow {
      position: relative;
    }
  }

  &.for-article {
    margin-bottom: 30px;
    .info-block-wrapper {
      min-height: 0;
    }
    .content {
      min-height: 0;
    }
    .game-buttons-wrap {
      min-height: 0;
    }
    .buttons {
      margin-bottom: 0;
    }
  }
}

.multi-info-block--desktop {
  .hide--desktop {
    display: none;
  }
}

.multi-info-block--mobile {
  @media @tablet {
    display: none;
  }
}

.tab {
  position: relative;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: scale(-1, -1);

  a {
    display: block;
    background-color: @accentLight;
    padding: 25px 15px;
    text-decoration: none;
    color: @white;
    font-weight: 700;
    cursor: pointer;

    @media @mobile {
      .fs(12);
    }
  }
}

.info-block__navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    display: inline-block;
    max-width: 100%;
    margin: 0 10px !important;
    min-width: 40ch;
  }

  .cycle-arrow {
    top: 0;
    left: auto !important;
    right: auto !important;
    transform: none;
  }
}

.info-block--accent-secondary {
  background: @accentSecondary;
  color: @fontColor;

  a {
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      color: @fontColorHover;
    }
  }
}
