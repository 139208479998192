// @font-face {
//   font-family: "Sofia Sans Light Oblique";
//   src: url("../fonts/SofiaSansExtraCondensed-LightItalic.woff") format("woff");
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Sofia Sans Thin Oblique";
//   src: url("../fonts/SofiaSansExtraCondensed-ExtraLightItalic.woff") format("woff");
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "Sofia Sans";
  src: url("../fonts/SofiaSansExtraCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Sans";
  src: url("../fonts/SofiaSansExtraCondensed-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Sans";
  src: url("../fonts/SofiaSansExtraCondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Sans";
  src: url("../fonts/SofiaSansExtraCondensed-SemiBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Font Stacks
#font {
  .default(@size: 16, @weight: 400, @lineHeight: 1.5em) {
    font-family: "Montserrat", Helvetica, Arial, Verdana, sans-serif;
    .fs(@size);
    line-height: @lineHeight;
    font-weight: @weight;
    .optimize-font;
    letter-spacing: 0;
  }

  .heading(@size: 30, @weight: 700, @lineHeight: 1.2em) {
    font-family: "Sofia Sans", Helvetica, Arial, Verdana, sans-serif;
    .fs(@size);
    line-height: @lineHeight;
    font-weight: @weight;
    letter-spacing: 0;
    .optimize-font;
    text-decoration: none;
    text-transform: uppercase;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?pv66nr");
  src: url("../fonts/icomoon.eot?pv66nr#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.woff?pv66nr") format("truetype"),
    url("../fonts/icomoon.woff?pv66nr") format("woff"),
    url("../fonts/icomoon.svg?pv66nr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography.less
 * Headings, body text, lists, code, and more for a versatile and durable typography system
 * ---------------------------------------------------------------------------------------- */

/* BODY */

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;

  a {
    font-family: inherit;
    font-size: inherit;
    text-transform: none;
    color: inherit;
  }

  &.large {
    .fs(18);
  }
  &.small {
    .fs(14);
  }
}

.small-text {
  .fs(14);
}

strong,
.strong {
  font-weight: 700;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  color: @linkColor;
  text-decoration: underline;
  .transition(0.2s);
  #font > .heading;
  .fs(18);

  &:hover,
  &:focus {
    color: @accentLight;
  }
  &:focus,
  &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @media @tablet {
    .fs(22);
  }

  @media @desktop {
    .fs(26);
  }

  &.default-font {
    #font > .default;
    color: @fontColor;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      text-decoration: underline;
    }
  }
}

a[href^="tel"] {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

a:not([href]) {
  color: @fontColor;
  text-decoration: none;
}

p,
.rte {
  li {
    a {
      font-size: inherit;
      font-family: inherit;
      text-transform: none;
      text-decoration: none;
      color: @fontColor;
      font-weight: inherit;
      display: inline-block;
    }
  }
}

.fade-link {
  opacity: 1;
  display: block;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
a.fancybox,
a.fancybox-iframe {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

span {
  &.highlight {
    padding: 0px 4px;
    background-color: @accentLight;
    color: @white;
    &.light {
      background-color: @accentLighter;
      color: @grayDarker;
    }
    &.dark {
      background-color: @accentHover;
      color: @white;
    }
    &.yellow {
      background-color: #fff415;
      color: @grayDarker;
    }
  }
}

/* HEADINGS */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  #font > .heading;
  color: currentColor;
  line-height: 1em;
  margin: 0 0 0.4em;
  display: block;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: currentColor;

    &.small-text {
      .fs(22);
    }

    &:hover,
    &:focus {
      color: @accentHover;
    }
  }
  &.light {
    color: @accentSecondary;
  }

  &.lighter {
    color: @white;
    font-weight: 500;
  }
}

h1,
.h1 {
  .fs(40);
  font-weight: 700;
  text-transform: uppercase;
  inline-size: 100%;
  overflow-wrap: break-word;
  hyphens: auto;

  &.smaller {
    @media @tablet {
      .fs(40);
    }
  }

  &.lower {
    text-transform: initial;
  }

  @media @tablet {
    .fs(42);
  }
  @media @desktop {
    .fs(52);
  }
}

h2,
.h2 {
  .fs(32);
  font-weight: 700;
  margin-bottom: 0.6em;

  &.smaller {
    @media @tablet {
      .fs(32);
    }
  }

  @media @tablet {
    .fs(42);
  }
}

h3,
.h3 {
  .fs(24);
  font-weight: 700;
  inline-size: 100%;
  overflow-wrap: break-word;
  hyphens: auto;

  &.lower {
    text-transform: initial;
  }

  @media @tablet {
    .fs(28);
  }
}

h4,
.h4 {
  font-weight: 700;
  .fs(22);

  &.small {
    .fs(20);
  }

  &.lc {
    text-transform: initial;
  }

  @media @tablet {
    .fs(24);

    &.small {
      .fs(22);
    }
  }
}

h5,
.h5 {
}

h6,
.h6 {
}

/* LISTS */
ul,
ol {
  margin: 0 0 1.5em 16px;
  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: square;
  ul {
    list-style: square;
  }

  &.blocks {
    list-style: none;
    margin-left: 0;
    li {
      position: relative;
      padding-left: 18px;
      p {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        margin-top: 9px;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        background-color: @accentLight;
      }
    }
  }

  &.space {
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &.lined {
    .unstyled;
    li {
      border-bottom: 1px solid @grayMiddle;
      padding: 10px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &.small-list {
    list-style: none;
    .fs(14);
    margin: 0;
  }
}

.list--inline {
  list-style: none;
  margin: 0 0 -10px 0;
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  flex-wrap: wrap;

  li {
    padding-left: 10px;
    margin-bottom: 10px;

    + li {
      &:before {
        content: "-";
        display: inline-block;
        color: @white;
        padding-right: 10px;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled,
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  ul {
    list-style: none;
  }
  li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none;
  }
}

dl {
  .unstyled;
  dt {
  }
  dd {
    font-weight: 700;
    border-bottom: 1px solid @grayMiddle;
    padding: 5px 0 15px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0px solid @grayMiddle;
      padding-bottom: 0;
    }
  }
}

/* MISC */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid @grayMiddle;
  margin: 30px 0 30px;
  &.strong {
    border-top: 3px solid @grayMiddle;
  }
  &.dark {
    border-color: @gray;
  }
}

blockquote {
  color: @accentLight;
  line-height: 1.4em;
  font-style: italic;
  font-weight: 500;
  .fs(18);
}

#eid-safari-extension-is-installed {
  display: none;
}

.list--unstyled {
  list-style: none;
  margin: 0;
}

.fw-light {
  font-weight: 300;
}
