/* Micro clearfix hack for clearing floats - h5bp.com/q */
.clearfix {
	*zoom: 1;
	&:before, &:after { display: table; content: " "; }
	&:after { clear: both; }
}

/* Image replacement */
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden { display: none !important; visibility: hidden; }

.hide-mobile {
  display: none;

  @media @tablet {
    display: block;
  }
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.vh { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.vh.focusable:active, .vh.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible { visibility: hidden; }

/* User select */
.user-select (@val: none) {
    -webkit-touch-callout:  @val;
    -webkit-user-select:    @val;
    -khtml-user-select:     @val;
    -moz-user-select:       @val;
    -ms-user-select:        @val;
    user-select:            @val;
}

// Scale
.zoom(@ratio: 1) {
    zoom: @ratio;
    -moz-transform: scale(@ratio);
    -moz-transform-origin: 0 0;
}

// Appearance
.appearance(@appearance: none) {
  -webkit-appearance: @appearance;
      -ms-appearance: @appearance;
       -o-appearance: @appearance;
          appearance: @appearance;
}

// Placeholder
.placeholder(@color: @grayLight) {
    ::-webkit-input-placeholder { color: @color; }
    ::-moz-placeholder { color: @color; }
    :-moz-placeholder { color: @color; }
    :-ms-input-placeholder { color: @color;  }
}

// Text Overflow
.text-truncate { text-overflow: ellipsis; vertical-align: top; white-space: nowrap; overflow: hidden; display: inline-block; }

// Border Radius
.border-radius(@radius: 5px) {
	border-radius: @radius;
}

.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	border-top-left-radius: @radius;
}


// Drop shadows
.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
	-webkit-box-shadow: @shadow;
	box-shadow: @shadow;
}

// Box
.box(@box: box) {
	display: block;
	display: -webkit-box;
    display: -moz-box;
    display: @box;
}

// Ordinal group
.box-ordinal-group(@order) {
	-webkit-box-ordinal-group: @order;
	-moz-box-ordinal-group: @order;
	box-ordinal-group: @order;
}

// Box orient
.box-orient(@orient: vertical) {
	-webkit-box-orient: @orient;
    -moz-box-orient: @orient;
    box-orient: @orient;
}

/* Flexbox */
.flex-box(@flex: flex) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: @flex;
}

/* Direction of the elements inside a flexbox */
/* row | row-reverse | column | column-reverse */
.flex-direction(@direction: column) {
	-webkit-flex-direction: column;
	flex-direction: column;
}

/* Order elements (int) inside a flexbox */
.flexbox-order(@order) {
	-webkit-box-ordinal-group: @order;
	-moz-box-ordinal-group: @order;
	-ms-flex-order: @order;
	-webkit-order: @order;
	order: @order;
}

/* Single line or multiple lines inside a flexbox */
/* nowrap | wrap | wrap-reverse */
.flex-box-wrap(@wrap: wrap) {
	-webkit-flex-wrap: @wrap;
	-ms-flex-wrap: @wrap;
	flex-wrap: @wrap;
}

/* Length of the item relative to the rest of the flexible items */
.flexbox-flex(@width: 1) {
	-webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}



// Box orient
.box-orient(@orient: vertical) {
	-webkit-box-orient: @orient;
    -moz-box-orient: @orient;
    box-orient: @orient;
    flex-direction: row;
}


// Box sizing
.border-box(@sizing: border-box){
	-moz-box-sizing: @sizing; -webkit-box-sizing: @sizing; box-sizing: @sizing;
	*behavior: url('../js/polyfills/boxsizing.htc');
}

.content-box(@sizing: content-box){
	-webkit-box-sizing: @sizing;
	   -moz-box-sizing: @sizing;
	   		box-sizing: @sizing;
}

// Background size
.background-size(@size:cover){
	-webkit-background-size: @size;
  	   -moz-background-size: @size;
         -o-background-size: @size;
            background-size: @size;
}

// Transitions
.transition(@transition:0.3s) {
	-webkit-transition: @transition;
	   -moz-transition: @transition;
	   	 -o-transition: @transition;
	        transition: @transition;
}

.transition-delay(@delay:0.2s) {
	-webkit-transition-delay: @delay;
	   -moz-transition-delay: @delay;
	   	 -o-transition-delay: @delay;
	        transition-delay: @delay;
}

.transitionprop(@prop: all, @time: 1s, @ease: linear) {
    -webkit-transition: @prop @time @ease;
    -moz-transition: @prop @time @ease;
    -o-transition: @prop @time @ease;
    transition: @prop @time @ease;
}

// GPU Rendering
.enable-webkit-gpu(@perspective:1000) {
	/* enable gpu rendering (ios hack) */
	-webkit-transform : translateZ(0);
	transform : translateZ(0);

	.backface-visibility;

	-webkit-perspective: @perspective;
	perspective: @perspective;
}

.transform-style(@style:preserve-3d) {
	-webkit-transform-style: @style;
	-moz-transform-style: @style;
	transform-style: @style;
}
.backface-visibility(@visibility:hidden) {
	-webkit-backface-visibility: @visibility;
	-moz-backface-visibility: @visibility;
	backface-visibility: @visibility;
}
.perspective(@perspective) {
	-webkit-perspective: @perspective;
	-moz-perspective: @perspective;
	perspective: @perspective;
}
.perspective-origin(@perspectiveOrigin) {
	-webkit-perspective-origin: @perspectiveOrigin;
	-moz-perspective-origin: @perspectiveOrigin;
	perspective-origin: @perspectiveOrigin;
}


// Background clipping
.background-clip(@clip) {
	-webkit-background-clip: @clip;
	   -moz-background-clip: @clip;
	        background-clip: @clip;
}
.background-clip-border() {
	-moz-background-clip: border;
	-webkit-background-clip: border;
	background-clip: border-box;
}

// Alpha colors
.background-color-with-alpha (@color, @alpha) {
  @rgba: rgba(red(@color), green(@color), blue(@color), @alpha);
  /* background-color-with-alpha *///
  background-color: @color;
  background-color: @rgba;
  // No longer using MS filters because they break border radius in IE9
  // @argb: argb(@rgba);
  // filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr=@{argb}, endColorstr=@{argb})";
  // -ms-filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr=@{argb}, endColorstr=@{argb})";
}

.border-color-with-alpha (@color, @alpha) {
  @rgba: rgba(red(@color), green(@color), blue(@color), @alpha);
  /* border-color-with-alpha *///
  border-color: @color;
  border-color: @rgba;
}

// Gradients
#gradient {
  .horizontal (@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, right top, from(@startColor), to(@endColor)); // Konqueror
    background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, @startColor), color-stop(100%, @endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(left, @startColor, @endColor); // Le standard
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",@startColor,@endColor)); // IE9 and down
  }
  .vertical (@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(@startColor), to(@endColor)); // Konqueror
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @startColor), color-stop(100%, @endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(top, @startColor, @endColor); // The standard
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down
  }
  .directional (@startColor: #555, @endColor: #333, @deg: 45deg) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
    background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
  }
  .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: @endColor;
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
    background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down, gets no color-stop at all for proper fallback
  }
}

// transform
.transform(@function) {
	transform: @function;
	-ms-transform: @function; /* IE 9 */
	-moz-transform: @function; /* Firefox */
	-webkit-transform: @function; /* Safari and Chrome */
	-o-transform: @function; /* Opera */
}

// transform origin
.transform-origin(@function) {
	transform-origin: @function;
	-ms-transform-origin: @function; /* IE 9 */
	-moz-transform-origin: @function; /* Firefox */
	-webkit-transform-origin: @function; /* Safari and Chrome */
	-o-transform-origin: @function; /* Opera */
}

// Filter
.filter(@filter) {
	filter: @filter;
	-o-filter: @filter;
	-ms-filter: @filter;
	-moz-filter: @filter;
	-webkit-filter: @filter;
}

// Opacity
.opacity(@opacity: 100) {
	opacity: @opacity / 100;
	.backface-visibility;
}

// Optimize font
.optimize-font(@smoothing:antialiased) {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: @smoothing;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: @smoothing;
}

// Font size
.fs(@size) {
  font-size: 0px + @size;
  font-size: 0rem + @size / @doc-font-size;
}

// Line height
.lh(@lh) {
	line-height: 0px + @lh;
	line-height: 0rem + @lh / @doc-font-size;
}

// Height
.h(@h) {
	height: 0px + @h;
	height: 0rem + @h / @doc-font-size;
}

// Margin
.m(@mt:0, @mr:0, @mb:0, @ml:0) {
	margin: (0px + @mt) (0px + @mr) (0px + @mb) (0px + @ml);
	margin: (0rem + @mt / @doc-font-size) (0rem + @mr / @doc-font-size) (0rem + @mb / @doc-font-size) (0rem + @ml / @doc-font-size);
}

.mt(@m) {
	margin-top: 0px + @m;
	margin-top: 0rem + @m / @doc-font-size;
}

.mb(@m) {
	margin-bottom: 0px + @m;
	margin-bottom: 0rem + @m / @doc-font-size;
}

// Retina
// A helper mixin for applying high-resolution background images (http://www.retinajs.com)
.at2x(@path, @at2x_path, @w: auto, @h: auto) {
  background-image: url(@path);
  @media all and (-webkit-min-device-pixel-ratio : 1.5) {
    background-image: url(@at2x_path);
    background-size: @w @h;
  }
}

// Selection
.selection(@background: @grayDark, @color: @white) {
    ::-moz-selection { background-color: @background; color: @color; }
    ::selection { background-color: @background; color: @color; }
}

// Animations
.animate(@name, @time: 4s, @iteration: infinite) {
	-webkit-animation: @name @time @iteration;
	-moz-animation:    @name @time @iteration;
	-o-animation:      @name @time @iteration;
	animation:         @name @time @iteration;
}

.animation-name(@name) {
	animation-name: @name;
	-webkit-animation-name: @name;
}

.animation-duration(@duration: 2s) {
	animation-duration: @duration;
	-webkit-animation-duration: @duration;
}

.animation-iteration-count(@count: infinite) {
	animation-iteration-count: @count;
	-webkit-animation-iteration-count: @count;
}


// Pseudo element
.pseudo-element(@top:0, @left:0) {
	position: absolute;
	content: "";
	top: @top;
	left: @left;
}

// Columns
.column-count(@count:2) {
	 -webkit-column-count: @count; /* Chrome, Safari, Opera */
    -moz-column-count: @count; /* Firefox */
    column-count: @count;
}

.column-gap(@gap:20px) {
	-webkit-column-gap: @gap; /* Chrome, Safari, Opera */
    -moz-column-gap: @gap; /* Firefox */
    column-gap: @gap;
}

.column-break-inside-avoid {
	-webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
}

/* ------------------------ */
/* LESS mixin for CSS arrow */
/* ------------------------ */

/* Usage
 * .arrow(size, color, direction, offset, border-size, border-color);
 */

/* Where
 * Size is the with of the arrow
 * Color is the color of the arrow (plain color required)
 * Direction is the orientation of the arrow (top, right, bottom, left)
 * Offset is the position of the arrow on its axis (px / em)
 * Border-size is the width of the border if there is one (optional; default "0")
 * Border-color is the color of the border if there is one (optional; default "inherit");
 */

/* Extra
 * Drop-shadows can be used on the element to create a shadow on the arrow as well
 */

.arrow(@size, @color, @direction, @offset) {
	position: absolute; content: ""; width: 0; height: 0;
}

.arrow(@size, @color, @direction, @offset) when (@direction = top) {

	left: @offset;
	margin-left: -@size;

	border-left: @size solid transparent;
	border-right: @size solid transparent;
	border-bottom: @size solid @color;

}

.arrow(@size, @color, @direction, @offset) when (@direction = bottom) {

    left: @offset;
    margin-left: -@size;

    border-left: @size solid transparent;
    border-right: @size solid transparent;
    border-top: @size solid @color;

}

.arrow(@size, @color, @direction, @offset) when (@direction = right) {

    left: 100%;
    top: @offset;
    margin-top: -@size;

    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-left: @size solid @color;

}

.arrow(@size, @color, @direction, @offset) when (@direction = left) {

    right: 100%;
    top: @offset;
    margin-top: -@size;

    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-right: @size solid @color;

}
