.modaal-fullscreen {
  &.modaal-wrapper {
    z-index: 100222;
  }

  .modaal-close {
    background: none !important;
    right: 0;
    top: 0;
  }

  .modaal-container {
    background: @accent;
  }

  .modaal-overlay {
    background-color: @accent;
  }

  .modaal-content-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.mobile-nav__list {
  #font > .heading;
  list-style: none;
  margin: 0 0 -20px 0;
  width: 100%;

  span,
  a.button.button--minimal,
  a {
    padding: 8px 0;
    color: @white;
    .fs(26);
  }

  a.button.button--minimal {
    #font > .default;
    .fs(18);
  }

  + .mobile-nav__list {
    margin-top: 3.5rem;
  }

  .button.link {
    color: @white;
    text-decoration: none;
  }

  li {
    margin-bottom: 10px;
  }

  &.mobile-nav__list--main {
    span,
    a {
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      .fs(26);
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin-bottom: 10px;
    }

    .accordion a,
    .accordion span {
      border-bottom: 0;
      margin-bottom: 10px;
    }

    li {
      ul {
        margin: 7px 0 0;

        li {
          line-height: 1em;
        }

        a {
          #font > .heading;
          padding: 0.1em 0;
          color: rgba(255, 255, 255, 0.75);
          text-transform: none;
          display: block;
          .fs(26);
          font-weight: 400;
        }
      }
    }
  }
}

.mobile-nav__list--sub {
  display: block;
  column-count: 2;

  a {
    text-transform: uppercase;
    column-gap: 2rem;
    .fs(22);
    font-weight: 400;
  }
}

.modaal-noscroll {
  overflow: visible !important;
}

.mobile-nav__logo {
  align-self: center;
  margin-bottom: 2rem;
}
