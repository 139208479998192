/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  z-index: 10;
  height: auto;
  margin: 0;
  margin-top: 60px;
  background: @accentHover;

  .row--footer {
    @media @mobile {
      .row__item {
        width: 50%;
      }
    }

    @media @tablet {
      .row__item {
        width: 33.333%;
      }
    }

    @media @desktop {
      .row__item {
        width: auto;
        flex: 1;
      }
    }
  }
  .sitemap {
    h4 {
      margin-top: 20px;
    }
    .one-fifth {
      width: 100%;
      @media @tablet {
        width: 20%;
      }
    }
    .small-list {
      a {
        color: @accent;
        text-decoration: none;
        &:hover,
        &:focus {
          color: @accentLight;
        }
      }
      + h4 {
        margin-top: 30px;
      }
      margin-bottom: 30px;
    }
  }

  .one-family {
    width: 550px;
    padding: 0 40px;
    display: block;
    margin: 0 auto;
  }

  .social-list {
    display: inline-flex;
    margin-top: 30px;
    gap: 20px;
  }

  .list--inline {
    a {
      color: @white;
      text-decoration: none;
    }
  }
}

.page-footer--mt-0 {
  margin-top: 0;
}

.page-footer__top {
  padding: 30px 0;

  @media @tablet {
    padding: 2vw 0;
  }
}

.page-footer__bottom {
  margin-top: 60px;
  padding: 30px 0;

  @media @tablet {
    padding: 2vw 0;
  }

  .section + .section {
    margin-top: 60px;
  }
}

a.esign {
  width: 104px;
  height: 20px;
  .at2x("../images/logo-esign.png", "../images/logo-esign@2x.png", 104px, 20px);
  text-indent: -9999px;
  .opacity(60);
  .transition(1s);
  display: block;
  outline: 0;
  &:hover,
  &:focus {
    .opacity(100);
  }

  @media @tablet {
    float: right;
  }
}

.footer-nav {
  list-style: none;
  flex-direction: column;
  margin-left: 0;

  a,
  span,
  p {
    color: @white;
    .fs(20);
    line-height: 2em;
    text-decoration: none;
  }

  .footer-nav__item--header {
    font-weight: 600;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    #font > .heading;
    text-decoration: none;

    a {
      #font > .heading;
      .fs(30);

      @media @tablet {
        font-weight: 700;
        .fs(35);
      }

      @media @desktop {
        .fs(40);
      }
    }

    span {
      line-height: 1.2em;
    }
  }
}

.footer-nav__item {
  line-height: 1.2em;

  + .footer-nav__item {
    margin-top: 0.6em;
  }

  a {
    #font > .default;
    text-transform: none;
    letter-spacing: 0;
    line-height: 1.2em;
  }
}
