.visual {
  + .rte {
    margin-top: 30px;
  }
}

.visual--square {
  position: relative;
  height: 0;
  padding-top: 100%;
  display: block;
  width: 100%;

  img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.visual--3x4 {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  padding-top: 75%;

  img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.visual--2x1 {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  padding-top: 50%;

  img {
    position: absolute;
    object-fit: contain;
    object-position: center;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.visual--16x9 {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  iframe,
  img,
  video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    min-width: 100%;
    min-height: 100%;
  }

  iframe {
    border: none;
  }
}

.visual--10x9 {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  padding-bottom: 90%;

  iframe,
  img,
  video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  iframe {
    border: none;
  }
}
