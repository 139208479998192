.info-card-grid > * {
  margin-bottom: 30px;
}

.info-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.16);
  background-color: @white;
}

.info-card__title {
  // for ref
}

// ugly fix to overrule specificity
.rte .info-card img {
  margin-bottom: 0;
}

.info-card__visual {
  position: relative;
  display: block;
  width: 100%;
}

.info-card__content {
  padding: 40px 40px 0 40px;
}

.info-card__footer {
  margin-top: auto;
  text-align: center;
  padding: 20px 30px 30px 30px;
}
