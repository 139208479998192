.page-header__wrap {
  display: flex;
  align-items: center;
  padding: 10px 0;

  @media @desktop {
    gap: 40px;
    padding: 20px 0;
  }

  .page-header__logoo img {
    @media @desktop {
      width: 140px;
    }
  }
}

.page-header--sso {
  .main-nav {
    margin-left: 0;
    background-color: transparent;

    .nav-wrap {
      display: block;
      width: 100%;
      margin-left: 0;

      li {
        &:last-of-type {
          @media @desktop {
            margin-left: auto;
            border-top: 2px solid @accent;
            border-bottom: 2px solid @accent;
          }
        }
      }
      @media @desktop {
        display: flex;
      }
    }

    a {
      padding: 0 0 0 10px;

      @media @desktop {
        padding: 15px 10px 15px;
      }
    }

    @media @desktop {
      width: 100%;
    }
  }
}

.sso-text {
  padding: 20px 0 40px;

  @media @tablet {
    padding: 20px 30px;
  }

  @media @wide {
    padding: 100px 80px;
  }
}

.sso-form {
  background-color: @white;
  color: @fontColor;
  box-shadow: 0px 20px 60px rgba(11, 45, 97, 0.2);
  padding: 20px;

  @media @desktop {
    padding: 60px;
  }
}

.sso-form__line {
  position: relative;
  display: flex;
  justify-content: center;
  color: @grayMiddle;
  margin: 30px 0;

  span {
    padding: 0 30px;
    background-color: @white;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    height: 1px;
    width: 100%;
    background-color: @grayMiddle;
    z-index: 0;
  }
}
