.accordion {
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  transition: 0.4s;
  width: 100%;

  + .accordion {
    margin-top: 20px;
  }

  .accordion {
    border: none;

    .accordion__title {
      font-size: 16px;
    }
    .accordion__trigger {
      padding: 10px 15px;

      &:after {
        width: calc(100% - 5px);
        left: 0;
        bottom: 0;
      }

      .icon-arrow {
        width: 40px;
      }
    }

    &.--open {
      background: white;
      padding: 5px 15px;
    }
  }
}

.accordion--minimal {
  + .accordion {
    margin-top: 0;
  }

  .accordion__trigger {
    padding: 0;
    background: transparent;

    .icon-arrow {
      padding: 0;
      width: 12px;
      height: 12px;
    }
  }
}

.accordion .accordion__trigger-icon {
  display: block;
  padding: 1.25rem 1.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}

.accordion__trigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  color: white;
  background: #00a4e4;
  width: 100%;
  border: none;
  position: relative;
  overflow: hidden;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  a {
    border-bottom: none;
  }

  &:after {
    content: "";
    width: calc(100% - 30px);
    display: block;
    height: 1px;
    left: 15px;
    right: 0;
    background: gray(400);
    position: absolute;
    bottom: -1px;
    transition: all 0.25s cubic-bezier(0, 0, 0.58, 1);
  }

  .icon-arrow {
    padding: 5px 10px;
    width: 40px;
    height: 22px;
    transform: rotate(0);
    margin-left: 8px;
    fill: white;
    transition: transform 0.25s cubic-bezier(0, 0, 0.58, 1);
  }

  &[href] {
    .icon-arrow {
      z-index: 3;
    }
  }

  &.accordion--open {
    .icon-arrow {
      transform: rotate(180deg);
      transition: transform 0.25s cubic-bezier(0, 0, 0.58, 1);
    }
    &:after {
      bottom: 0;
      transition: all 0.25s cubic-bezier(0, 0, 0.58, 1);
    }
  }

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

.accordion__title {
  flex-grow: 1;
  margin-bottom: 0;
  color: white;
  text-align: left;
  font-weight: 400;
}

.accordion__panel {
  display: none;
  list-style: none;
  margin: 0;
  will-change: auto;

  .rte {
    padding: 15px;
  }
}
