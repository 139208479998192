.tab-navigation-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow-x: scroll;

  @media @tablet {
    overflow-x: hidden;
    flex-direction: row;
    align-items: flex-end;
  }
}

.tab-navigation-wrap--center {
  justify-content: center;
}

.tab-navigation {
  list-style: none;
  display: flex;
  margin: 0;
}

.tab-navigation--inverse {
  .tab-navigation__item {
    background-color: @white;
    color: @accent;

    &:hover,
    &:active,
    &:focus,
    &--active {
      background-color: @accent;
      color: @white;

      a {
        color: @white;
      }
    }
  }
}

.tab-navigation__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: @accent;
  color: @white;
  transition: background-color 0.25s ease-in, color 0.25s ease-in;

  &:hover,
  &:active,
  &:focus,
  &--active {
    background-color: @white;
    color: @accent;

    a {
      color: @accent;
    }
  }
}

.tab-navigation__link {
  display: block;
  padding: 22px 25px 18px 25px;
  text-transform: uppercase;
  .fs(18);
  font-weight: 700;
  color: currentColor;
}
