.game-action-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media @tabletMini {
    gap: 40px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 10%;
    top: 0;
    bottom: 0;
    border-left: 3px dotted @accentGray;
  }
}

.game-action {
  display: flex;
  padding: 15px 15px;
  background-color: @white;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.05);

  @media @tabletMini {
    padding: 30px 40px;
  }

  .visual + span {
    display: block;
    margin-top: 20px;
  }
}

.game-action--tight {
  .game-action__content {
    padding: 0;
  }
}

.game-action__side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 15%;
  padding-right: 20px;
  border-right: 1px solid @accentGray;
}

.game-action__content {
  flex: 1;
  padding-left: 15px;

  @media @tablet {
    padding-left: 50px;
  }

  p {
    margin-bottom: 0.5em;
  }

  img {
    margin: 10px 0;
  }
}

.game-action__small-title {
  .fs(14);
  color: rgba(180, 180, 180, 0.8);
}
