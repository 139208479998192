.revealing-list {
  // for reference
}

.revealing-list--active {
  li {
   animation: reveal 0.35s both;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
