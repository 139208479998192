.note {
  margin: 0 0 15px;
  padding: 20px 15px;
  text-align: center;
  background: @grayLight;

  ul,
  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .section--light & {
    background-color: @white;
  }
}
