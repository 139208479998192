.product-tile {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    .product-tile__sizes {
      transform: translateX(0);
      transition: all $transitionDefault;
    }
  }
}

.product-tile--out-of-stock {
  .product-tile__sizes {
    transform: translateX(0);
    transition: all $transitionDefault;
  }
}

.product-tile--shadow {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: layer("cellar");
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: $dropShadow;
  }
}

.product-tile__visual {
  position: relative;
  background: @white;
  overflow: hidden;
}

.product-tile__content {
  background: #f3f7f9;
  padding: 3.75vw 2.8vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media @desktop {
    padding: 1.25vw 1.56vw;
  }

  @media @wide {
    padding: 20px 30px;
  }
}

.product-tile__name {
  .fs(24);
  display: block;

  @media @tablet {
    .fs(36);
  }
}

.product-tile__bottom {
  display: flex;
  flex-direction: row;
  margin-left: -1.56vw;
}

.product-tile__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.56vw;
  flex: 1;
}

.product-tile__price {
  .fs(18);
  margin-bottom: 0.6em;
  margin-left: -0.5em;

  span {
    margin-left: 0.5em;
  }
}

.product-tile__note {
  top: 10px;
  left: 10px;
  background: @accent;
  padding: 8px 12px;
  position: absolute;
  display: inline-block;
  color: @white;
  font-weight: 700;
  max-width: 15ch;
  line-height: 1.4em;
}

.product-tile__label {
  position: absolute;
  left: 0.75vw;
  top: 0.75vw;
  background: @accent;
  line-height: 1.5em;
  padding: 0.5em;
  border-radius: 100%;
  font-size: rem(13px);
  color: @white;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotate(-30deg);
  pointer-events: none;
}

.product-tile__link {
  .fs(16);
}
