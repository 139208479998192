/* Forms.less
 * Base styles for various input types, form layouts, and states
 * ------------------------------------------------------------- */

form {
  margin: 0;
}

.page-form {
  margin-top: 90px;
}

.form-element {
  position: relative;
  margin-bottom: 20px;

  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"],
  .button {
    float: none;
  }

  &.age {
    visibility: hidden; /* Honeypot */
  }

  .input-group {
    .clearfix;
    input {
      width: calc(~"100% - 80px");
      float: left;
      margin-bottom: 0;
    }
    .button {
      height: 45px;
      width: 80px;
      float: left;
    }
    &.squared {
      input {
        width: calc(~"100% - 45px");
      }
      .button {
        width: 45px;
      }
    }
  }
  &.form-element--light {
    color: @accent;

    input {
      background-color: @accentLighter;
      border: none;
      border-radius: 3px;
      color: @accent;
      padding: 25px;
    }

    input::placeholder {
      color: @accent;
      text-transform: lowercase;
    }

    a {
      display: inline-block;
      color: @accent;
      opacity: 0.5;
      padding: 10px 0;
    }
  }
  &.form-element--checkbox {
    label {
      font-weight: 700;
    }
  }
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  color: #fff;
  font-size: 18px;
}
button,
input,
select,
textarea,
.button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}

/* Prevent zoom on touch devices in active inputs */
@media @mobile {
  input,
  textarea {
    font-size: 16px;
  }
}

label,
.label,
input,
select,
textarea {
  #font > .default;
  vertical-align: middle;
  color: @accent;
  font-weight: 700;

  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    text-transform: none;
  }
}

label,
.label {
  display: block;
  margin-bottom: 8px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none;
}
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea,
select {
  padding: 6px 12px 5px;
  margin-bottom: 2px;
  display: block;
  font-weight: 400;
  background: #fff;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: 1px solid @gray;

  .transition;
  .border-radius(0px);
  &:focus {
    outline: 0;
    border-color: @black;
  }

  &:disabled {
    background-color: @grayLight;
  }
}

input[type="email"] {
  display: block;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 185px;
  padding: 14px 12px;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}
input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

button,
input[type="reset"],
input[type="submit"],
.button,
a.button {
  #font > .heading;
  letter-spacing: 0.02em;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  clear: both;
  .transition;
  outline: 0;
  line-height: 1.2em;
  padding: 0.5em 0.5em;
  margin: 0 0 0;
  background: @accentSecondary;
  .border-radius(3px);
  text-decoration: none;
  color: @fontColor;
  .fs(24);
  font-weight: 600;
  border: 1px solid @accentSecondary;

  img + span,
  i + span,
  span + img,
  span + i {
    margin-left: 15px;
  }

  i {
    z-index: 2;
    position: relative;
  }

  &:hover,
  &:focus,
  &:active {
    background: @accentSecondaryHover;
    border-color: @accentSecondaryHover;
  }

  &:active {
    .box-shadow(inset 0 1px 2px rgba(0,0,0,0.2));
  }

  &::-moz-focus-inner {
    border: 0;
  }

  //sizes
  &.small {
    padding: 0.2em 0.4em;
    .fs(20);
  }
  &.medium {
    padding: 9px 16px;
    .fs(18);
  }
  &.tablet--small {
    @media @tablet {
      padding: 15px 20px;
      .fs(20);
    }
    @media @wide {
      padding: 20px 30px;
      .fs(20);
    }
  }
  &.large {
    .fs(20);
    @media @tablet {
      .fs(22);
    }
    @media @desktop {
      .fs(24);
    }
  }

  &.full {
    width: 100%;
    float: none;
  }
  &.wide {
    padding-left: 35px;
    padding-right: 35px;
  }
  &.inline {
    display: inline-block;
    float: none;
  }

  //styles
  &.negative {
    background-color: #ff3f3e;
  }
  &.warning {
    background-color: #f1d608;
  }
  &.positive {
    background-color: #53cc57;
    &:hover,
    &:focus {
      background-color: darken(#53cc57, 20%);
    }
  }
  &.rounded {
    border-radius: 3px;
  }
  &.uppercase {
    text-transform: uppercase;
  }

  &.link {
    position: relative;
    padding: 0;
    .fs(22);
    color: @fontColor;
    background-color: transparent;
    border: none;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 0.1em;

    &:hover,
    &:focus {
      color: @accentHover;
      background: none;
    }

    &.button--light {
      color: @white;

      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.75);
        background: none;
      }
    }

    @media @tablet {
      .fs(22);
    }

    @media @desktop {
      .fs(22);
    }
  }

  &.more.more {
    position: relative;
    padding-right: 50px;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      .transform(translate3D(-100%,0,0));
      .transition;
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:after {
      font-family: "icomoon";
      content: "\e90a";
      display: block;
      position: absolute;
      z-index: 2;
      font-weight: 400;
      top: 50%;
      .fs(8);
      right: 24px;
      .transform(translateY(-50%));
      .transition;
    }
    &:hover,
    &:focus {
      background-color: @accentSecondaryHover;
      &:before {
        background-color: @accentSecondaryHover;
        .transform(translate3D(0,0,0));
      }
      &:after {
        right: 15px;
      }
    }
  }

  &.button--full {
    width: 100%;
  }

  &.button--center {
    text-align: center;
  }

  &.button--minimal {
    background-color: transparent !important;
    color: @accent;
    padding: 0;
    border: none;

    span {
      pointer-events: none;
    }

    &.link {
      color: @white;
      padding-right: 50px;
    }

    &:before {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      color: @accentLight !important;

      &:before {
        display: none;
      }
    }
  }

  &.button--secondary {
    color: @accentLight;
    border: 1px solid @accentLight;
    background: transparent;

    &.light {
      color: @white;
      border-color: @white;
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        color: @accentLight;
        background: @white;
      }
    }
  }

  &.with-large-icon.with-large-icon {
    padding-left: 100px;
    i {
      position: absolute;
      top: 50%;
      left: 30px;
      .fs(25);
      .transform(translateY(-50%));
      @media @tablet {
        .fs(40);
      }
    }
  }
  &.light {
    background-color: @accentLight;
    color: @white;
    &:hover,
    &:focus,
    &:active {
      background: @accentHover;
    }
  }
  &.mobile-full {
    @media @mobile {
      width: 100%;
      text-align: center;
    }
  }
  &.disabled {
    background-color: @grayMiddle;
    color: @gray;
  }
  &.prelabel {
    div {
      display: inline-block;
      text-align: left;
      .fs(16);
    }
    span {
      display: block;
      .fs(14);
    }
  }
  &.simple.simple {
    background-color: transparent;
    color: @accent;
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
    &:hover,
    &:focus {
      color: @accentHover;
    }
  }

  &.social {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    i {
      font-size: 20px;

      @media @xwide {
        font-size: 25px;
      }
    }

    @media @xwide {
      padding: 15px;
    }
  }

  //social
  &.facebook {
    background-color: @facebook;
    &:hover,
    &:focus {
      background-color: darken(@facebook, 10%);
    }
  }

  &.twitter {
    background-color: @twitter;
    &:hover,
    &:focus {
      background-color: darken(@twitter, 10%);
    }
  }

  &.linkedin {
    background-color: @linkedin;
    &:hover,
    &:focus {
      background-color: darken(@linkedin, 10%);
    }
  }

  &.googleplus {
    background-color: @googleplus;
    &:hover,
    &:focus {
      background-color: darken(@googleplus, 10%);
    }
  }
}

.button--cover {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.button-group--center {
    align-items: center;
  }

  &.button-group--justify-end {
    justify-content: flex-end;
  }

  &.button-group--equal {
    .button {
      flex: 1;
    }
  }

  @media @wide {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.button-group--row {
  flex-direction: row;
  flex-wrap: wrap;
}

.button-group--vertical {
  flex-direction: column;
}

button,
input[type="text"],
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}
button,
input[type="reset"],
input[type="submit"],
.button,
a.button,
select {
  &:focus {
    outline: 0;
    *outline: inherit;
    .box-shadow(0 0 1px 1px rgba(200,200,200,0.1));
  }
}

select {
  .appearance(none);
  background-image: url("../images/chevron-down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: ~"calc(100% - 10px)" 50%;
}

::-webkit-input-placeholder {
  color: #999;
  font-style: italic;
}
::-moz-placeholder {
  color: #999;
  font-style: italic;
}
:-ms-input-placeholder {
  color: #999;
  font-style: italic;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999;
  font-style: italic;
}

/*::-ms-reveal { display: none; } /* Hide reveal password ie 10+ */
::-ms-clear {
  display: none;
} /* Hide clear text field ie 10+ */

input[type="search"] {
  -webkit-appearance: none;
} /* Remove the rounded corners */
::-webkit-search-cancel-button {
  -webkit-appearance: none;
} /* Hide the cancel button */
::-webkit-search-results-button {
  -webkit-appearance: none;
} /* Hide the magnifying glass */

::-webkit-textfield-decoration-container {
}
::-webkit-inner-spin-button {
  -webkit-appearance: none;
} /* Hide number input spinner */
::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-ms-browse,
::-webkit-file-upload-button {
} /* Style file upload button */

/* forms */
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;
  p,
  ul,
  li {
    .clearfix;
  }
  ul {
    margin-left: -20px;
    li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 10px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px;

      &.error {
        input {
          border-color: @error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media @tablet {
        &.one-half {
          width: 50%;
        }
        &.one-fourth {
          width: 25%;
        }
      }
    }
  }
}

.buttons {
  list-style: none;
  margin: 0;
  .clearfix;

  li {
    float: left;
    a {
      &.active {
        background-color: @accentHover;
      }
    }
  }

  &.filled {
    display: table;
    width: 100%;
    li {
      display: table-cell;
      float: none;
      .button {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }

  &.center {
    display: inline-block;
  }

  &.full {
    li {
      width: 100%;
      .button {
        display: block;
        width: 100%;
      }
    }
  }

  &.full-mobile {
    @media @mobile {
      display: block;
      li {
        float: none;
      }
      .button {
        display: block;
        width: 100%;
      }
    }
  }

  &.seperated {
    li {
      border-right: 2px solid @white;
      &:last-child {
        border-right: 0px solid transparent;
      }
    }
  }

  &.seperated-top {
    li {
      border-top: 2px solid @white;
    }
  }

  &.smaller {
    .button {
      .fs(14);
      @media @desktop {
        .fs(16);
      }
      @media @xwide {
        .fs(18);
      }
    }
  }

  &.small {
    .button {
      .fs(12);
      padding: 8px 10px;
    }
  }

  &.split-mobile {
    @media @mobile {
      li {
        display: block;
        min-width: 50%;
        float: left;
        margin-bottom: 2px;
        border-right: 0px solid @white;
        &:nth-child(odd) {
          border-right: 2px solid @white;
        }
      }
    }
  }
}

.a-c {
  .button,
  a.button,
  button {
    float: none;
    clear: none;
    display: inline-block;
  }
}

.select-wrapper {
  position: relative;
  &:after {
    content: "\e906";
    font-family: "Icomoon";
    color: @accent;
    position: absolute;
    right: 2px;
    top: 50%;
    line-height: 40px;
    background-color: @white;
    width: 50px;
    height: 40px;
    pointer-events: none;
    .transform(translateY(-50%));
    display: none;
  }
  select {
    line-height: 1.8em;
  }
  select::-ms-expand {
    display: none;
  }
}

/*
 * Custom checkbox
 */

/* Base for label styling */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-top: 12px;
}

/* checkbox aspect */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 20px;
  height: 20px;
  border: 1px solid @gray;
  .border-radius(0px);
  background-color: @white;
}
/* checked mark aspect */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  content: "✔";
  position: absolute;
  top: -2px;
  left: 3px;
  .fs(24);
  color: @accentLight;
  transition: all 0.2s;
}
/* checked mark aspect changes */
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: @grayLight;
}
input[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
input[type="checkbox"]:disabled + label {
  color: #aaa;
}
input[type="checkbox"] + label {
  font-weight: 400;
}
/* accessibility */
input[type="checkbox"]:checked + label:before {
  border-color: @accent;
}

.checkbox__description {
  display: block;
  color: @accent;
  margin-left: 30px;
  line-height: 1.5em;
}

/*
 * Custom radiobutton
 */

input[type="radio"] {
  // display:none;
  visibility: hidden;
  position: absolute;
  width: 100%;
}

input[type="radio"] + label {
  font-weight: 400;
  cursor: pointer;
}

input[type="radio"] + label span {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  margin: -1px 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

input[type="radio"] + label span {
  background-color: @white;
  border: 1px solid @gray;
}

input[type="radio"]:checked + label span {
  background-color: @white;
  border: 1px solid @accent;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px;
    height: 12px;
    .border-radius(50%);
    .transform(translateX(-50%) translateY(-50%));
    background-color: @accentLight;
  }
}

.radio-group--tile {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  label {
    color: @accent;
    background-color: @accentLighter;
    padding: 15px 30px;
    border-radius: 3px;
    border: 1px solid transparent;
  }

  input[type="radio"]:checked,
  input[type="radio"]:hover {
    + label {
      border-color: @accent;
    }
  }
}
