/*
 * Tables.less
 * Tables for, you guessed it, tabular data
 * ---------------------------------------- */

table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 0;
  .fs(16);

  th,
  td {
    padding: 6px 6px;
    text-align: left;
    vertical-align: middle;
    line-height: 1.5em;

    &.a-l.a-l {
      text-align: left;
    }
    &.a-r.a-r {
      text-align: right;
    }

    &.mw100 {
      @media @tablet {
        min-width: 100px;
      }
    }
    &.mw40 {
      min-width: 40px;
    }
    &.highlight {
      color: @accent;
      font-weight: 700;
    }

    &.td--video {
      padding: 20px 6px 40px 6px;
    }
  }
  th {
    #font > .heading;
    .fs(22);
    font-weight: bold;

    @media @tablet {
      .fs(24);
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: 0 solid @gray;
      }
    }

    &.highlight {
      color: @accent;
      font-weight: 700;
    }
  }

  &.center {
    td,
    th {
      text-align: center;
    }
  }

  &.mobile-small {
    @media @mobile {
      .fs(12);
    }
  }
}

.td--video.td--video--highlight {
  display: block;
  padding: 20px;
  margin: 20px 6px 40px 6px;
  border-radius: 15px;
  background: @accent;

  .td__video-description {
    color: @white;
  }
}

.td__video-description {
  display: block;
  margin-top: 15px;
  .fs(14);
}

.table {
  .td--valign-top {
    vertical-align: top;
  }
}

.table-mobile-small {
  @media @mobile {
    .fs(12);
  }
}

.table-ranking {
  .fs(15);

  th {
    min-width: 36px;
    border-top: 1px solid @accentGray;
    border-bottom: 1px solid @accentGray;
  }

  td {
    border-top: 1px solid @accentLighter;
  }

  tr:first-child td {
    border-top: none;
  }

  tr:last-child td {
    border-bottom: 1px solid @accentLighter;
  }

  .dashed-line td {
    border-bottom: 1px dashed @accent;
  }

  th,
  td {
    @media @mobile {
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        display: none;
      }
    }
  }

  .highlight {
    background-color: @accentLighter;
  }

  .highlight-accent {
    background-color: @accentGray;
  }

  @media @tablet {
    .fs(16);
  }
}

.rank {
  .fs(18);
  text-align: left !important;

  @media @tablet {
    .fs(16);
  }
}

.table-ranking__title {
  #font > .heading;
  font-weight: 700;
  .fs(22);

  @media @tablet {
    .fs(24);
  }
}

.ranking-small {
  .mobile-hide-table-cell {
    display: none;
    @media @mobile {
      display: none;
    }
  }
}

.club-icon {
  max-height: 40px;
  width: 40px;
}
